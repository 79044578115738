.Label {
    background-color: $cc-blue-15;
    color: $cc-dark-blue;
    display: inline-block;
    text-transform: uppercase;
    font-size: u(14);
    padding: u(8, $spacing-unit--xs * 5, $spacing-unit--xs);
    border-radius: u(18);
    margin-right: u(10);
    font-weight: 400;

    &--transparent {
        background-color: transparent;
    }

    &--border {
        border: u(1) solid $cc-dark-blue-10;
    }

    &--link {
        @include transition(border);

        &:active,
        &:focus,
        &:hover,
        &.is-hovered,
        &.is-active {
            color: $cc-dark-blue;
            @include transition(border);
            border: u(1) solid $cc-dark-blue;
            text-decoration: none;
        }
    }
}