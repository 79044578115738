.Tile {
    padding: u($spacing-unit--xs * 7, $spacing-unit--xs * 6);
    border: u(1) solid $cc-dark-blue-10;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    margin-bottom: u($spacing-unit--xs * 7);
    color: $cc-dark-blue;
    font-weight: 400;
    position: relative;
    min-height: u(300);

    &--small {
        padding: u($spacing-unit--xs * 4, $spacing-unit--xs * 6);
        margin-bottom: u($spacing-unit--xs * 5);
        min-height: auto;
    }

    &::after {
        content: "";
        @include transition(opacity);
        @include position(absolute, 0, 0, 0, 0);
        @include dimensions(100%);
        box-shadow: u(0) u(5) u(30) $cc-dark-blue-10;
        opacity: 0;
    }

    &:active,
    &:focus,
    &:hover,
    &.is-hovered {
        &[href] {
            color: $cc-dark-blue;

            &::after {
                opacity: 1;
            }
        }
    }

    &-body {
        flex-grow: 2;

        img {
            max-height: u(64);
        }

        h5 {
            margin: u($spacing-unit, 0, $spacing-unit--xs);
        }
    }

    &-footer {
        border-top: u(1) solid $cc-blue-25;
        padding-top: u($spacing-unit);

        .Author {
            font-size: u(14);
        }

        .Function {
            font-size: u(14);
            color: $cc-dark-blue-50
        }
    }
}
