/* Pagination */
.Nav--pagination {
    padding-top: u($spacing-unit--xs * 3);
    margin-bottom: u(120);

    // List
    .Nav-list {

    }

    // Item
    .Nav-item {
    }

    // Link
    .Nav-link {
        @include dimensions(45);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $cc-dark-blue;
        @include transition(#{background-color, color});
        margin-left: u(5);
        margin-right: u(5);

        &.is-active {
            background-color: $cc-dark-blue-20;
        }

        &:active,
        &:focus,
        &:hover {
            color: $white;
            
            background-color: $cc-dark-blue;
        }
    }
}
