/* List */
ol,
ul {
    margin-bottom: u($spacing-unit);
    padding-left: u($list-gutter--l);

    li {
        margin-bottom: u(12);
    }

    ol,
    ul {
        margin-top: u(4);
        margin-bottom: u(4);
    }
}

// Unordered List
ul {
    list-style-type: none;

    li {
        position: relative;

        &:not(.Nav-item) {
            &::before {
                content: "";
                @include dimensions(7);
                background-color: $cc-blue;
                position: absolute;
                left: u(-29);
                border-radius: 50%;
                top: u(6);
            }
        }
    }
}

ul ul {
    list-style-type: none;
}

ul ul ul {
    list-style-type: none;
}

// Ordered List
ol {
    padding-left: u($list-gutter--l - 10);
    list-style-type: decimal;

    li {
        padding-left: u($list-gutter--l - 9);
    }
}

ol ol {
    padding-left: u($list-gutter--l + 9);
    list-style-type: upper-alpha;
}

ol ol ol {
    list-style-type: lower-roman;
}

ol ol ol ol {
    list-style-type: lower-alpha;
}

// Definition List
dt {
    font-weight: 700;
}