/* Objects: FlexEmbed */

// Namespace
$flexEmbed-namespace:                   $namespace !default;

// Ratios
$flexEmbed-ratios: (
    "1by1": (1:1),
    "2by1": (2:1),
    "3by1": (3:1),
    "3by2": (3:2),
    "4by3": (4:3),
    "16by9": (16:9),
    "map": (1400: 485)
) !default;