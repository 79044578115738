/* Lang */
.Nav--lang {

    @include mq($from: $viewport--lg) {
        @include position(relative, $z: 1);
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(:hover) {

            .Nav-list {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    @include mq($until: $viewport--lg - 1) {
        margin-top: u($spacing-unit);
    }

    // Toggle
    .Nav-toggle {
        cursor: default;
        align-items: center;
        display: flex;
        text-transform: uppercase;
        font-size: u(14);

        .Caret {
            @include caret(8, 1, bottom, currentColor, $header-bg-color);
            margin-left: u($spacing-unit--xs);
        }
    }

    // List
    .Nav-list {

        @include mq($from: $viewport--lg) {
            @include transition(#{opacity, visibility});
            @include position(absolute, $top: 100%, $right: 0);
            color: $black;
            background-color: $white;
            flex-direction: column;
            margin-top: u($spacing-unit--sm);
        }

        @include mq($until: $viewport--lg - 1) {
            justify-content: center;
            text-transform: uppercase;
        }
    }

    // Link
    .Nav-link {
        color: rgba($cc-dark-blue, 0.5);
        text-transform: capitalize;

        @include mq($from: $viewport--lg) {
            padding: u($spacing-unit--xs * 2, $spacing-unit--xs * 4, $spacing-unit--xs * 2, $spacing-unit--xs * 2);
            cursor: pointer;
            @include transition(border-left);
            border-left: u(4) solid transparent;
            font-weight: 400;
        }

        @include mq($until: $viewport--lg - 1) {
            padding: u($spacing-unit--xs);
            text-transform: uppercase;

            &::before {
                content: attr(lang);
            }
            
            @include mq($until: $viewport--lg - 1) {
                font-weight: 400;
            }
        }

        &.is-active {

            @include mq($from: $viewport--lg) {
                border-left: u(4) solid $cc-blue;
                color: $cc-blue;
                @include transition(border-left);
            }


        }

        &:not(.is-active):hover {
            @include mq($from: $viewport--lg) {
                border-left: u(4) solid $cc-blue;
                color: $cc-blue;
            }
        }
    }
}
