/* Main */
html {
    background-color: $bg-color;
    color: $text-color;
    font-family: $font-sans;
    height: 100%;

    &.fonts-loaded {
        font-family: $font-catamaran;
    }

    &.hide-scroll {
        overflow: hidden;
    }
}

body {
    min-height: 100%;
}
