/* Elements: HR */

// Dimensions
$hr-height:                   2 !default;
$hr-width:                    45 !default;

// Spacing
$hr-spacing--t:               $spacing-unit !default;
$hr-spacing--b:               $hr-spacing--t - $hr-height !default;

// Color
$hr-color-border:             $cc-blue !default;
