/* Button */

.Button {

    @keyframes arrow {
        0% {
            transform: translate(0)
        }
        50% {
            transform: translate(-4px)
        }
        to {
            transform: translate(0)
        }
    }

    .Icon-arrow {
        path {
            @include transition(fill);
        }
    }

    &:active,
    &:focus,
    &:hover,
    &.is-hovered,
    &.is-active {
        .Icon-arrow {
            animation: arrow .8s infinite;
            animation-duration: 0.8s;
            animation-timing-function: ease;
            animation-delay: 0s;
            animation-iteration-count: infinite;
            animation-direction: normal;
            animation-fill-mode: none;
            animation-play-state: running;
            animation-name: arrow;
        }
    }

    // Primary
    &--primary {
        background-color: $cc-blue;
        color: $white;
        font-weight: 500;

        &:not(.Button--outline) {

            .Icon-arrow {
                path {
                    fill: $white;
                }
            }

            &:active,
            &:focus,
            &:hover,
            &.is-hovered,
            &.is-active {
                color: $white;
                background-color: $cc-dark-blue;
            }
        }

        &.Button--outline {
            color: $cc-blue;

            .Icon-arrow {
                path {
                    fill: $cc-blue;
                }
            }

            &:active,
            &:focus,
            &:hover,
            &.is-hovered,
            &.is-active {
                background-color: $cc-blue;
                color: $white;
                border-color: $cc-blue;

                .Icon-arrow {
                    path {
                        fill: $white;
                    }
                }
            }
        }
    }

    // Secondary
    &--secondary {
        background-color: $cc-blue;
        color: $white;
        border-radius: 0;
        padding: u($spacing-unit--xs * 5);
        font-size: u(20);
        font-weight: 700;

        &:not(.Button--outline) {

            .Icon-arrow {
                path {
                    fill: $white;
                }
            }

            &:active,
            &:focus,
            &:hover,
            &.is-hovered,
            &.is-active {
                color: $white;
                background-color: $cc-dark-blue;
            }
        }

        &.Button--outline {
            color: $cc-blue;

            .Icon-arrow {
                path {
                    fill: $cc-blue;
                }
            }

            &:active,
            &:focus,
            &:hover,
            &.is-hovered,
            &.is-active {
                background-color: $cc-blue;
                color: $white;

                .Icon-arrow {
                    path {
                        fill: $white;
                    }
                }
            }
        }
    }

    // Tertiary
    &--tertiary {
        background-color: transparent;
        color: $cc-dark-blue;
        font-weight: 500;
        border-radius: 0;
        padding: u($spacing-unit--xs * 3, 0);
        border-bottom: u(1) solid $cc-blue;
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: u(18);

        .Icon-arrow {
            path {
                fill: $cc-blue;
            }
        }

        &:active,
        &:focus,
        &:hover,
        &.is-hovered,
        &.is-active {
            color: $cc-blue;

            .Icon-arrow {
                path {
                    fill: $cc-blue;
                }
            }
        }
    }

    // Info
    &--info {
        background-color: $blue;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-hovered,
            &.is-active {
                background-color: $blue--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $blue;

            &:active,
            &:focus,
            &:hover,
            &.is-hovered,
            &.is-active {
                color: $blue--700;
            }
        }
    }

    // Success
    &--success {
        background-color: $green;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-hovered,
            &.is-active {
                background-color: $green--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $green;

            &:active,
            &:focus,
            &:hover,
            &.is-hovered,
            &.is-active {
                color: $green--700;
            }
        }
    }

    // Warning
    &--warning {
        background-color: $orange;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-hovered,
            &.is-active {
                background-color: $orange--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $orange;

            &:active,
            &:focus,
            &:hover,
            &.is-hovered,
            &.is-active {
                color: $orange--700;
            }
        }
    }

    // Danger
    &--danger {
        background-color: $red;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-hovered,
            &.is-active {
                background-color: $red--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $red;

            &:active,
            &:focus,
            &:hover,
            &.is-hovered,
            &.is-active {
                color: $red--700;
            }
        }
    }

    // Outline
    &--outline {
        background-color: transparent;
        border-color: currentColor;
    }

    // Large
    &--large {
        @include font-size($button-font-size * 1.25, false);
        padding-right: u($button-padding--r * 2);
        padding-left: u($button-padding--l * 2);
    }

    // Small
    &--small {
        @include font-size($button-font-size * .75, false);
        padding-right: u($button-padding--r / 1.5);
        padding-left: u($button-padding--l / 1.5);
    }

    // Full
    &--full {
        display: block;
        width: 100%;
    }

    // Bare
    &--bare {
        border: 0;
        border-radius: 0;
        padding: 0;
        color: $cc-dark-blue-50;
        justify-content: flex-start;

        &:active,
        &:focus,
        &:hover,
        &.is-hovered,
        &.is-active {
            color: $cc-dark-blue;

            .Icon-arrow-bare {
                circle {
                    fill: $cc-dark-blue;
                }
            }

            .Icon-arrow-circle-outline {
                path {
                    fill: $white;
                    @include transition(fill);
                }
    
                circle {
                    fill: $cc-dark-blue;
                    stroke: transparent;
                    @include transition(#{stroke, fill});
                }
    
                g {
                    opacity: 1;
                    @include transition(opacity);
                } 
            }
        }

        .Icon-arrow-bare {
            circle {
                @include transition(fill);
                fill: $cc-dark-blue-50;
            }

            path {
                @include transition(stroke);
                stroke: $cc-white;
            }
        }

        .Icon-arrow-circle-outline {
            circle {
                @include transition(#{stroke, fill});
                fill: transparent;
            }

            path {
                @include transition(#{stroke, fill});
                stroke: transparent;
            }
        }
    }
}
