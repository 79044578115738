/* Paragraph */
p {
    margin-bottom: u($spacing-unit);
    max-width: u(800);

    b, strong {
        color: $cc-blue;
    }
}

.#{$namespace}Lead {
    font-size: u($font-size * $paragraph-lead-modifier);
}
