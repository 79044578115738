/* Cookie */
.CookieConsent {
    @include position(fixed, 0, 0, 0, 0, 4);
    @include transition(#{opacity, visibility}, 200ms, linear);
    background-color: $cookie-background-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:not(.is-active) {
        opacity: 0;
        visibility: hidden;
    }

    &-inner {
        background-color: $cookie-body-color;
        color: $cookie-text-color;
        padding: u($spacing-unit--lg);
        max-width: u(700);
        max-height: 90%;
        width: 90%;
        overflow-y: auto;
    }

    a {
        font-weight: 600;

        &,
        &:active,
        &:focus,
        &:hover {
            color: $cookie-link-color;
        }
    }

    &-close {
        cursor: pointer;
        display: block;
        flex-shrink: 0;
        font-size: 150%;
        margin-left: u($spacing-unit);
        line-height: 1;
        transition-property: opacity;
        transition-duration: 200ms;

        &:not(:hover) {
            opacity: .75;
        }
    }
}

.CookieGroups {

    .CookieItems {
        .Table {
            border-spacing: 0 !important;

            @include mq($until: $viewport--md) {
                tr {
                    margin-bottom: u($spacing-unit);
                }

                tr,
                td {
                    border: none !important;
                }
            }

            &-cell {
                border: none;
                margin-bottom: 15px;
                font-size: 16px;
                &:first-child{
                    hyphens: auto;
                }

                @include mq($until: $viewport--md) {
                    &::before {
                        background-color: $cc-grey !important;
                        border: none !important;
                        font-weight: bold;
                    }

                    &:first-child {
                        &::before {
                            background-color: $cc-dark-blue !important;
                            color: $white;
                        }
                    }
                }
            }

            thead {
                color: $white;
                background-color: $cc-dark-blue;
                
                th {
                    padding: u(10, 20);

                    @include mq($until: $viewport--md - 1) {
                        padding: u(10, 10);
                    }
                }
            }

            tbody tr {
                td {
                    padding: u(10, 20);
                    border-bottom: 1px solid $cc-dark-blue-25;

                    @include mq($until: $viewport--md - 1) {
                        padding: u(10, 10);
                    }
                }

                &:last-child {
                    td {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.cookie-though {
    // Change CSS variables of Cookie Though
    --ct-primary-400: #0047A0;
}