/* General */

// LazySizes
.LazyLoad,
.LazyLoading {
    opacity: 0;
}

.LazyLoaded {
    @include transition(opacity, 300ms);
    opacity: 1;
}

.js-slider--partners-home {
    .Slider-items {
        .Slider-item {
            img, svg {
                filter: grayscale(100%);
                @include transition(filter);
            }

            &:hover,
            &:active {
                img, svg {
                    filter: grayscale(0%);
                    @include transition(filter);
                }
            }
        }
    }
}

.Pagination {
    display: flex;
    justify-content: center;
    
    .paginationjs {
        .paginationjs-pages {
            .Pagination {
                &-page {
                    border: none;

                    @include dimensions(45);
                    border-radius: 50%;

                    &.active {
                        a {
                            background-color: $cc-dark-blue-20;
                        }
                    }

                    a {
                        @include dimensions(45);
                        border-radius: 50%;
                        background-color: transparent;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $cc-dark-blue;
                        font-weight: 600;
                        font-size: u(16);
                    }
            
                    &::before {
                        content: none;
                    }
                }
            }
        }
    }

}

.is-sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: u(40);
}

.Filter {
    padding: u(40, 45, 25);
    border: u(1) solid $cc-dark-blue-10;
    display: flex;
    flex-direction: column;
    background-color: $white;
    margin-bottom: u(20);

    @include mq($until: $viewport--lg - 1) {
        padding: u(21, 25);
        margin-bottom: u(35);
    }
}

.Search {
    background-color: $white;
    margin-bottom: u(35);
    border: u(1) solid $cc-dark-blue-10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: u(25, 45, 25, 45);

    @include mq($until: $viewport--lg - 1) {
        padding: u(10, $spacing-unit--xs * 5);
    }

    @include mq($until: $viewport--md - 1) {
        padding: u(10, $spacing-unit--xs * 5);
        margin-bottom: u($spacing-unit--xs * 3);
    }

    .Input {
        border: none;
        font-size: u(20);
        width: 100%;
        height: u(20);
        height: u(41);
        line-height: u(41);

        @include mq($until: $viewport--lg - 1) {
            font-size: u(16);
        }

        &:focus {
            outline: none;
        }
        
        &::placeholder {
            font-weight: 700;
            color: $cc-dark-blue-25;
        }        
    }

    .Remove {
        @include dimensions(18);
        background-image: url("../img/icons/remove.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
    }

    .Magnifier {
        @include dimensions(24);
        background-image: url("../img/icons/magnifier.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
    }
}

.PageHeader {
    border-bottom: u(1) solid $cc-dark-blue-50;
    margin-bottom: u($spacing-unit--xs * 7);

    @include mq($until: $viewport--md - 1) {

        &.has-filter {
            margin-bottom: u(140);
        }
    } 

    .Container {
        display: flex;
        justify-content: space-between;

        .Meta {
            font-size: u(18);
            color: $cc-dark-blue-50;
            margin-bottom: u(10);
        }

    }

    &--filter {
        align-items: flex-end;
        justify-content: flex-end;
        display: flex;
        padding-bottom: u(38);
        width: 100%;

        @include mq($until: $viewport--md - 1) {
            max-width: u(260);
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            position: absolute;
            z-index: 4;
            bottom: u(-160);
            padding-bottom: u(20);

            p {
                text-align: left !important;
            }
        }

        p {
            color: $cc-dark-blue;
            font-weight: 500;
            font-size: u(17);
            width: 100%;
            margin-bottom: u(12);
            text-align: right;
            margin-right: u($spacing-unit--xs * 5);
        }
    }

    &.has-back {
        .Back {
            position: absolute;
            right: u(40);
            top: 0;

            @include mq($until: $viewport--md - 1) {
                right: u(20);
            }
        }
    }

    .Container {
        padding-top: u($spacing-unit--xs * 11);
        padding-bottom: u(0);
        position: relative;

        h1 {
            display: inline-block;
            border-bottom: u(6) solid $cc-dark-blue;
            padding-bottom: u($spacing-unit--sm * 4);
            margin-bottom: u(0);
        }
    }
}

.Partner {
    &.Media {
        &-object {
            display: flex;
            justify-content: center;

            img {
                width: auto;
                max-height: 80px;
                height: auto;
                max-width: 120px;
            }
        }
    }
}

.Partners {
    // margin-bottom: u(100);

    &--detail {

        .Logo {
            background-color: $white;
            padding: u($spacing-unit--xs * 7, $spacing-unit--xs * 4);
            display: flex;
            justify-content: center;
            border: u(1) solid $cc-dark-blue-10;

            @include mq($until: $viewport--md - 1) {
                padding: u($spacing-unit--xs * 7, $spacing-unit--xs * 10);
                
                img {
                    max-width: u(200);
                }
            }
        }
    }
}

.Sponsors {
    .Sponsor {
        padding: u(0, $spacing-unit--xs * 5, $spacing-unit--xs * 6);

        @include mq($until: $viewport--lg - 1) {
            padding: u(0, 0, $spacing-unit--xs * 6);
        }

        h6 {
            color: $cc-dark-blue;
        }
    }
}

.Sidebar {
    @include mq($until: $viewport--lg - 1) {
        display: none;
    }

    hr {
        margin-top: u(15);
    }

    &--jobs {
        h5 {
            font-weight: 600;
            margin-bottom: u(5);
            margin-top: u(20);
    
            &:first-of-type {
                margin-top: 0;
            } 
        }
    
        .Button {
            &--bare {
                padding-top: u(5);
                padding-left: 0;
                justify-content: flex-start;
                color: $cc-dark-blue-50;
                font-weight: 500;
    
                svg {
                    opacity: 1;
                    height: u(20);
                }
    
                &:hover {
                    color: $cc-dark-blue;
                }
            }
        }
    }

    &--blog {

        .Meta {
            margin-top: u(20);
            font-size: u(14);
            color: $cc-dark-blue-50;
            margin-bottom: u(5);

            &:first-of-type {
                margin-top: 0;
            } 
        }

        h5 {
            font-weight: 600;
            margin-bottom: u(5);
        }
    
        .Button {
            &--bare {
                padding-top: u(5);
                padding-left: 0;
                justify-content: flex-start;
                color: $cc-dark-blue-50;
                font-weight: 500;
    
                svg {
                    opacity: 1;
                    height: u(20);
                }
    
                &:hover {
                    color: $cc-dark-blue;
                }
            }
        }
    }

    a {
        color: $cc-dark-blue;
        font-weight: 400;
        padding: u($spacing-unit--xs * 3, 3);
        border-bottom: u(1) solid $cc-dark-blue-50;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            @include transition(opacity);
            opacity: 0;
            height: u(13);
            margin-bottom: u(4);
        }

        &:hover {
            svg {
                opacity: 1;
            }
        }

        &:first-of-type {
            border-bottom: none;
            padding: u(0, 0);
            font-weight: 700;
            color: $cc-blue;
            font-size: u(20);

            &.router-link {
                &-exact-active {
                    font-weight: 700;
                }
            } 
        }

        &:last-of-type {
            border-bottom: 0;
        }

        &.router-link {
            &-exact-active {
                font-weight: 600;

                svg {
                    opacity: 1;
                }
            }
        }

        &.is-active {
            font-weight: 700;
        }
    }
}

.Dropdown {
    display: none;
    position: relative;
    z-index: 2;

    &--blog {
        display: block;
        max-width: u(220);
        width: 100%;

        .Dropdown-label {
            border: u(1) solid $cc-dark-blue;
            padding: u(11, 30, 10);
            border-radius: u(25);
            font-size: u(17);
            font-weight: 500;
            color: $cc-dark-blue-50;

            .Caret {
                @include caret(10, 1, bottom, $cc-dark-blue-50, $white);
                @include transition(transform);
            }
        }

        &.is-active {

            .Dropdown-items {
                width: calc(100%) !important;
                left: 0 !important;                
            }
        }
    }

    @include mq($until: $viewport--lg - 1) {
        display: block;
    }

    &-label {
        font-weight: 700;
        color: $cc-dark-blue-25;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .Caret {
            @include caret(12, 1, bottom, currentColor, $white);
            @include transition(transform);
        }
    }

    &.is-active {
        .Dropdown {

            &-label {
                .Caret {
                    transform: rotate(-180deg);
                }
            }

            &-items {
                position: absolute;
                width: calc(100% + 52px);
                background: $white;
                padding-left: 25px;
                padding-right: 25px;
                left: -26px;
                z-index: 2;
                display: flex;
                flex-direction: column;
                margin-top: u(15);
                padding-top: u(10);
                padding-bottom: u(20);
                border: u(1) solid $cc-dark-blue-10;
                top: u($spacing-unit--xs * 9);

                .router-link-exact-active {
                    color: $cc-blue;
                    font-weight: 700;
                } 

                &::after {
                    content: "";
                    @include transition(opacity);
                    @include position(absolute, 0, 0, 0, 0);
                    @include dimensions(100%);
                    box-shadow: u(0) u(5) u(30) $cc-dark-blue-10;
                    z-index: -1;
                }

                .Meta {
                    font-size: u(12);
                    color: $cc-dark-blue-50;
                    margin-bottom: u(10);
                }

                .u-linkClean {
                    &:last-of-type {
                        .Meta {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    } 

    &-items {
        display: none;

        a {
            padding-top: u(10);
            color: $cc-dark-blue;
            font-weight: 400;
        }
    }

}

.Blue {
    color: $cc-blue;
}

.White {
    color: $white;
}


.Intro {
    margin-bottom: u($spacing-unit--xs * 5);
    font-size: u(20);
    
    p {
        line-height: 30px;
    }
}

.TextWithImage {

    .Grid-cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
} 

.Party {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: $cc-dark-blue;

    h5 {
        margin-top: u($spacing-unit--sm * 3);
        margin-bottom: u(7);
    }

    p {
        font-weight: 400;
        margin-bottom: u($spacing-unit--sm * 4);

        @include mq($until: $viewport--md - 1) {
            padding: u(0, 40);
        }
    }
    

    &:active,
    &:focus,
    &:hover,
    &.is-hovered {
        color: $cc-dark-blue;

        .Circle {
            @include transition(background-color);
            background-color: $cc-blue;

            .Icon {
                &-party {
                    display: none;
    
                    &-hover {
                        display: block;
                    }
                }
            }
        }
    }

    .Circle {
        @include dimensions(70);
        @include transition(background-color);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        position: relative;

        &::after {
            content: "";
            @include transition(opacity);
            @include position(absolute, 0, 0, 0, 0);
            @include dimensions(100%);
            box-shadow: u(0) u(5) u(30) $cc-dark-blue-10;
            border-radius: 50%;
        }

        .Icon {
            &-party {
                display: block;

                &-hover {
                    display: none;
                }
            }
        }
    }
}

.Form {
    &-wrapper {
        max-width: u(886);
    }
}

.Contact {
    &Page {
        .Background {
            &--white {
                padding-top: u(100);
                padding-bottom: u(100);
                background-color: $white;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
    
                @include mq($until: $viewport--lg - 1) {
                    padding-top: u(40);
                    padding-bottom: u(40);
                }
            }

            .Form {
                &-wrapper {
                    padding: u(0, 40);
                }
            }
        }

        .FlexEmbed--map {
            &:before {
                @include mq($until: $viewport--lg - 1) {
                    padding-bottom: 50%;
                }
            }
        }

        .GoogleMaps {
            position: relative;
            margin-top: u(35);
            margin-bottom: u(120);

            @include mq($until: $viewport--lg - 1) {
                margin-top: u(0);
                margin-bottom: 0;
            }
        }
    }

    &-info {
        padding: u(50);
        background-color: $cc-blue;
        color: $white;
        position: absolute;
        z-index: 1;
        bottom: 0;
        transform: translateY(50%);
        left: calc(100% * 1 / 12);

        @include mq($until: $viewport--lg - 1) {
            position: static;
            transform: none;
        }

        h3 {
            margin-bottom: u(10);
        }

        p {
            margin: 0;

            a {
                color: $white;
                font-weight: 400;
            }
        }
    }


}

// Dropdown
.Form-item {
    &.is-active {
        .Select {
            &-selected {
                color: $cc-dark-blue;
                padding-left: u($form-element-padding--l);
            }
        }
    }
}

.CustomSelect {
    position: relative;
    border-bottom: 1px solid $cc-dark-blue-25;
    max-width: u($form-element--default);

    @include mq($until: $viewport--md - 1) {
        max-width: u(800);
    }

    &.is-active {
        &::after {
            @include transition(transform);
            transform: translateY(-50%) rotate(-180deg);
        }
    }

    &::after {
        @include dimensions($spacing-unit--sm, 7);
        @include transition(transform);
        content: "";
        position: absolute;
        background-image: url("../img/icons/arrow-select.svg");
        right: 0;
        background-repeat: no-repeat;
        background-size: u($spacing-unit--sm);
        top: 50%;
        transform: translateY(-50%);
    }

    select {
        display: none;
    }

    .Select {
        &-selected {
            color: $cc-dark-blue;
            padding: u($form-element-padding--t - 2, $form-element-padding--r, $form-element-padding--b - 2, 0);
            cursor: pointer;
            user-select: none;
    
            &:after {
                position: absolute;
                content: "";
                top: 14px;
                right: 10px;
                width: 8px;
                height: 8px;
                transform: rotate(135deg);
                opacity: 0.5;
                border: solid 2px $cc-grey;
                border-left: 0;
                border-bottom: 0;
                display: block;
                margin-bottom: 2px;
                @include transition(border-color);
            }
    
            .Select-arrow-active:after {
                transform: rotate(-45deg);
                top: u(17);
            }
        }

        &-items {
            padding: u(10, 20);
            position: absolute;
            background-color: $white;
            top: 100%;
            left: 0;
            right: 0;
            z-index: 99;

            div {
                color: $cc-dark-blue;
                padding: 10px 0px 0px 0px;
                border: 1px solid transparent;
                border-color: transparent transparent $white transparent;
                cursor: pointer;
                user-select: none;

                &.is-active {
                    color: $cc-blue !important;
                    font-weight: 700;
                } 
        
                &:last-of-type {
                    border: none;
                    padding-bottom: 10px;
                }
            }

            &::after {
                content: "";
                @include transition(opacity);
                @include position(absolute, 0, 0, 0, 0);
                @include dimensions(100%);
                box-shadow: u(0) u(5) u(30) $cc-dark-blue-10;
                z-index: -1;
            }
        }
        
        &-hide {
            display: none;
        }
    }
}

.Article {
    margin-bottom: u(55);
    position: relative;

    &.u-first {
        .Thumbnail {
            position: relative;
            min-height: 543px;

            img {
                height: 100%;
                object-fit: contain;
                width: 100%;
                position: absolute;
                background-color: white;
                padding: u(40)
            }
    
            &::after {
                content: "";
                @include position(absolute, 0, 0, 0, 0, 1);
                background-image: linear-gradient(to right, rgba($cc-dark-blue, 1), rgba($cc-white, 0));
                background-color: transparent;
                opacity: 1;
                @include transition(opacity);
            }
        }

        &:active,
        &:hover {
            .Thumbnail {
                &::after {
                    opacity: 1;
                    @include transition(opacity);
                }
            }

            .Button {
                &--bare {
                    .Icon-arrow-bare {
                        circle {
                            fill: rgba($white, 1);
                        }

                        path {
                            stroke: rgba($cc-dark-blue, 1);
                        }
                    }
                }
            }
        }
    }

    .Overlay {
        @include position(absolute, u(35), u(60), u(60), u(160), 2);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        $overlay-width: 480;

        @include mq($until: $viewport--md - 1) {
            @include position(absolute, u(15), u(20), u(40), u(120), 3);
        }

        * {
            color: $white;
        }

        .Meta {
            color: $white;
            font-size: u(18);
            max-width: u($overlay-width);
        }

        h3 {
            font-size: u(37);
            max-width: u($overlay-width);
        }

        p {
            font-size: u(18);
            max-width: u($overlay-width);
        } 
        
        .Button {
            &--bare {
                font-size: u(18);
                display: flex;
                align-items: center;
                .Icon-arrow-bare {
                    circle {
                        fill: rgba($white, 0.5);
                    }
                }
            }
        }
    }

    .Meta {
        color: $cc-dark-blue-50;
        font-size: u(14);
        margin-top: u(25);
        margin-bottom: u(10);
    }

    .Thumbnail {
        position: relative;

        &::after {
            content: "";
            @include position(absolute, 0, 0, 0, 0, 1);
            background-color: $cc-dark-blue;
            opacity: 0;
            @include transition(opacity);
        }
    }

    &:active,
    &:hover {
        .Thumbnail {
            &::after {
                opacity: 0.35;
                @include transition(opacity);
            }
        }
    }


}

.ArticlePage {
    h2, h3 {
        color: $cc-blue;
    }

    h2 {
        margin-bottom: u(30);
    }

    h3 {
        margin-bottom: u(20);
    }

    h4 {
        color: $cc-dark-blue;
        margin-bottom: u(20);
    }

    .Text {
        margin-bottom: u(45);
    }

    .Media {
        &-object {
            margin-bottom: u(45);
            display: inline-block;

            &--video {
                .Media-object {
                    margin-bottom: 0;
                }
            }
        }
    }

    .Images {
        margin-bottom: u(30);

        .Media {
            &-object {
                margin-bottom: u(15);
            }
        }
    }

    .Author {
        padding-top: u(40);
        margin-top: u(25);
        margin-bottom: u(120);
        border-top: u(1) solid $cc-blue;

        @include mq($until: $viewport--lg - 1) {
            margin-bottom: u(40);
        }

        .Avatar {
            position: relative;
            @include dimensions(76);
            border-radius: 50%;
            overflow: hidden;
            display: inline-block;

            img {
                @include position(absolute, 0, 0, 0, 0, 1);
                object-fit: cover;
            }
        }
    }
}

.Quote {
    padding: u(15) u(160) u(50) u(60);
    font-weight: 700;
    color: $cc-blue;
    font-size: u(30);
    line-height: u(40);
    display: flex;

    @include mq($until: $viewport--md - 1) {
        padding: u(15) u(30) u(50) u(30);
    }

    @include mq($until: $viewport--sm - 1) {
        padding: u(15) u(0) u(50) u(0);
    }

    .Decoration {
        @include dimensions(183);
        background-color: $white;
        position: relative;
        display: flex;
        margin-right: u(70);

        @include mq($until: $viewport--md - 1) {
            @include dimensions(120);
            margin-right: u(40);
        }

        @include mq($until: $viewport--sm - 1) {
            @include dimensions(60);
            margin-right: u(40);
        }

        .Icon {
            position: absolute;
            right: -20px;

            @include mq($until: $viewport--md - 1) {
                @include dimensions(40);
                right: -10px;
            }

            @include mq($until: $viewport--sm - 1) {
                @include dimensions(30);
                right: -10px;
            }
        }
    }

    span {
        width: calc(100% - 253px);
        display: flex;
        align-items: center;

        @include mq($until: $viewport--md - 1) {
            width: calc(100% - 193px);
            right: -10px;
        }

        @include mq($until: $viewport--sm - 1) {
            width: calc(100% - 133px);
            right: -10px;
        }
    }
}

.Banner {
    background-color: $cc-grey;
    z-index: 0;
    @include position(relative);

    @include mq($until: $viewport--xl - 1) {
        padding-bottom: u($spacing-unit--md * 6);
    }

    @include mq($until: $viewport--lg - 1) {
        padding-bottom: u(40);
    }

    .Grid-cell {
        &:last-of-type {
            padding-bottom: u(120);

            @include mq($until: $viewport--lg - 1) {
                padding-bottom: u(0);
            }
        }
    }

    &--home {
        background-color: $cc-grey;
        padding-bottom: u(160);
        
        .Grid {
            .Grid-cell {
                display: flex;
                flex-direction: column;
                justify-content: center;

                h1 {
                    font-size: u(60);

                    @include mq($until: $viewport--md - 1) {
                        @include font-size(35, $heading-spacing-1);
                    }
                }

                .Text {
                    margin-top: u(-10);
                    margin-bottom: u(5);
                    display: inline-flex;
                    padding-left: u(100);
                    position: relative;
                    font-size: u(18);

                    @include mq($until: $viewport--md - 1) {
                        padding-left: 0;
                        font-size: u(16);
                    }

                    .Decoration {
                        width: u(71);
                        height: u(2);
                        background-color: $cc-blue;
                        position: absolute;
                        left: 0;
                        top: u(5);

                        @include mq($until: $viewport--md - 1) {
                            display: none;
                        }
                    }
                }

                &:last-of-type {
                    display: flex;
                    align-items: center;

                    @include mq($until: $viewport--lg - 1) {
                        margin-top: u(100);
                        justify-content: center;
                    }
    
                    @include mq($until: $viewport--md - 1) {
                        margin-top: u(40);
                        justify-content: center;
                    }
                }

                > svg, img {
                    width: 100%;
                    max-width: u(700);
                    height: auto;
                }
            }
        }
    }

    h2 {
        p {
            margin-bottom: u(0);
        }
    }

    img {

        @include mq($until: $viewport--xl - 1) {
            height: calc(100% + 120px);
            width: 100%;
            object-fit: cover;
        }

        @include mq($until: $viewport--lg - 1) {
            max-height: 500px;
            height: 100%;
        }
    }
    
    &::after {
        content: "";
        @include position(absolute);
        bottom: 0;
        height: u($spacing-unit--md * 6);
        width: 100%;
        background-color: $white;
        z-index: -1;

        @include mq($until: $viewport--lg - 1) {
            content: none;
        }
    }
}

.Vacancies {
    margin-top: -15px;
    margin-bottom: u(25);
}

.Step {

    .Number {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        border-radius: 50%;
        background-color: $cc-blue;
        @include dimensions(54);
        font-size: u(25);
        font-weight: 700;
        margin-bottom: u(15);
    }

    p {
        font-size: u(18);
    }
}

.Value {
    h6 {
        color: $cc-dark-blue;
        margin-top: u(25);
    }

    p {
        text-align: center;
    }
}

.CallToAction {
    background-color: $cc-grey;
    padding: u(100, 0);
    margin-top: u(100);
    position: relative;

    &::after {
        content: "";
        height: u(200);
        width: 100%;
        background-color: $white;
        position: absolute;
        top: -200px;
        z-index: -1;
    }

    &--white {
        background-color: $white;

        &::after {
            content: "";
            background-color: $cc-grey;
        }
    }
    
    @include mq($until: $viewport--lg - 1) {
        margin-top: u(0);

        &::after {
            display: none;
        }
    }

    .Grid {
        .Grid-cell {
            &:last-of-type {
                margin-top: u(-200);
                display: flex;
                justify-content: flex-end;

                svg, img {
                    width: 100%;
                    max-width: u(630);
                    height: auto;
                }

                @include mq($until: $viewport--lg - 1) {
                    margin-top: u(100);
                    justify-content: center;
                }

                @include mq($until: $viewport--md - 1) {
                    margin-top: u(20);
                    justify-content: center;
                }
            }
        }
    }
}

.Link {
    @include mq($until: $viewport--md - 1) {
        margin-bottom: u(10);
        margin-right: u(10);
    }

    + .Link {
        margin-left: u(10);

        @include mq($until: $viewport--md - 1) {
            margin-left: u(0);
        }
    }
}

.Medium {
    font-weight: 500;
}

.Border {
    &-top {
        border-top: u(1) solid $cc-dark-blue-10;
    }

    &-right {
        border-right: u(1) solid $cc-dark-blue-10;
    }

    &-bottom {
        border-bottom: u(1) solid $cc-dark-blue-10;
    }

    &-left {
        border-left: u(1) solid $cc-dark-blue-10;
    }
}

.KeypointConnect {
    position: absolute;
    top: 0;
    right: u(40);
    font-size: u(14);

    @include mq($until: $viewport--lg - 1) {
        right: auto;
        top: auto;
        left: 50%;
        transform: translateX(-50%);
        bottom: u(70);
        width: 100%;
        text-align: center;
    }
}

.Bold {
    font-weight: 700;
}

.Newsletter {
    max-width: u(290);
    width: 100%;

    p {
        color: rgba($white, 0.5);
        font-size: u(14);
    } 

    .Form {
        max-width: u(290);
        width: 100%;
        margin-top: 0;

        .Form-item {
            .Form {
                &-field {
                    border: u(2) solid rgba($white, 0.5);
                    padding: u(0, 20);
                    font-size: u(16);

                    &.Form-field--input {
                        background-image: url("../img/icons/send.svg");
                        background-position: calc(100% - 14px) center;
                        background-size: 16px;
                        background-repeat: no-repeat;
                        color: $white;
                        height: 40px;
                        line-height: 40px;
                    }
                }
            }
        }
    }

    .Button--bare {
        color: $white;
        @include transition(color);

        svg {
            circle {
                fill: $white;
            }

            path {
                stroke: $cc-dark-blue;
            }
        }

        &:hover,
        &:active,
        &:focus {
            color: $cc-pink;

            svg {
                circle {
                    fill: $cc-pink;
                }
    
                path {
                    stroke: $cc-dark-blue;
                }
            }
        }
    }
}

.u-negativeMarginTop {
    margin-top: u(-30);
}