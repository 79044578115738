/* Colors: Custom */

// Grey
$cc-grey:                           #F7F7F7 !default;
$cc-grey--hover:                    scale-color($cc-grey, $lightness: -30%);
$cc-blue:                           #0047A0 !default;
$cc-blue-25:                        rgba($cc-blue, 0.25) !default;
$cc-blue-15:                        rgba($cc-blue, 0.15) !default;
$cc-dark-grey:                      #7B8895 !default;
$cc-dark-blue:                      #001B35 !default;
$cc-dark-blue-50:                   rgba($cc-dark-blue, 0.5) !default;
$cc-dark-blue-25:                   rgba($cc-dark-blue, 0.25) !default;
$cc-dark-blue-20:                   rgba($cc-dark-blue, 0.2) !default;
$cc-dark-blue-10:                   rgba($cc-dark-blue, 0.1) !default;
$cc-pink:                           #F50057 !default;
$cc-white:                          #FFF !default;
$cc-white-50:                       rgba($cc-white, 0.5) !default;