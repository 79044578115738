/* Footer */
.Footer {
    background-color: $footer-bg-color;
    color: $white;

    a {
        color: $white;
        font-weight: 400;

        &:hover, &:active {
            color: $cc-pink;
        }
    }

    .Logo {
        svg {
            display: block;
            width: u(235);
    
            @include mq($until: $viewport--md - 1) {
                width: u(150);
            }
        }
    }

    // Top
    &-top {
        padding-top: u($spacing-unit * 5);
        padding-bottom: u($spacing-unit * 3);
    }

    // Bottom
    &-bottom {
        border-top: 1px solid rgba($black, .1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: u($spacing-unit--sm);
        padding-bottom: u($spacing-unit--xs * 7);

        .Nav--footer {
            .Nav-list {
                .Nav-item {
                    color: rgba($white, 0.5);

                    .Nav-link {
                        color: rgba($white, 0.5);

                        &:hover, &:active {
                            color: $cc-pink;
                        }
                    }
                }
            }
        }

        .Nav--copyright {
            .Nav-list {
                .Nav-item {
                    color: rgba($white, 0.5);
                    font-size: u(13);
                    padding-left: u(35);

                    &:first-of-type {
                        padding-left: 0;
                    }

                    .Nav-link {
                        color: rgba($white, 0.5);

                        &:hover, &:active {
                            color: $cc-pink;
                        }
                    }
                }
            }
        }
    }
}
