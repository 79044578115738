/* Style */
/* Settings: Base */
/* Settings: Colors */
/* Colors: Palette */
/* Colors: Custom */
/* Colors: Theme */
/* Settings: Fonts */
/* tamil */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("Catamaran Thin"), local("Catamaran-Thin"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0OIpQoyXQa2RxT7-5jhgRUQ_mu72BiBLE.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC; }

/* latin-ext */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("Catamaran Thin"), local("Catamaran-Thin"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0OIpQoyXQa2RxT7-5jhgRMQ_mu72BiBLE.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("Catamaran Thin"), local("Catamaran-Thin"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0OIpQoyXQa2RxT7-5jhgRCQ_mu72Bi.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* tamil */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local("Catamaran ExtraLight"), local("Catamaran-ExtraLight"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jKhVVctyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC; }

/* latin-ext */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local("Catamaran ExtraLight"), local("Catamaran-ExtraLight"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jKhVVatyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local("Catamaran ExtraLight"), local("Catamaran-ExtraLight"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jKhVVZNyBx2pqPA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* tamil */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Catamaran Light"), local("Catamaran-Light"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jThZVctyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC; }

/* latin-ext */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Catamaran Light"), local("Catamaran-Light"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jThZVatyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Catamaran Light"), local("Catamaran-Light"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jThZVZNyBx2pqPA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* tamil */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Catamaran Regular"), local("Catamaran-Regular"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0IIpQoyXQa2RxT7-5r8zRASf6M7VBj.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC; }

/* latin-ext */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Catamaran Regular"), local("Catamaran-Regular"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0IIpQoyXQa2RxT7-5r6zRASf6M7VBj.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Catamaran Regular"), local("Catamaran-Regular"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0IIpQoyXQa2RxT7-5r5TRASf6M7Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* tamil */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Catamaran Medium"), local("Catamaran-Medium"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jFhdVctyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC; }

/* latin-ext */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Catamaran Medium"), local("Catamaran-Medium"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jFhdVatyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Catamaran Medium"), local("Catamaran-Medium"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jFhdVZNyBx2pqPA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* tamil */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Catamaran SemiBold"), local("Catamaran-SemiBold"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jOhBVctyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC; }

/* latin-ext */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Catamaran SemiBold"), local("Catamaran-SemiBold"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jOhBVatyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Catamaran SemiBold"), local("Catamaran-SemiBold"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jOhBVZNyBx2pqPA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* tamil */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Catamaran Bold"), local("Catamaran-Bold"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jXhFVctyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC; }

/* latin-ext */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Catamaran Bold"), local("Catamaran-Bold"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jXhFVatyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Catamaran Bold"), local("Catamaran-Bold"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jXhFVZNyBx2pqPA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* tamil */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Catamaran ExtraBold"), local("Catamaran-ExtraBold"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jQhJVctyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC; }

/* latin-ext */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Catamaran ExtraBold"), local("Catamaran-ExtraBold"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jQhJVatyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Catamaran ExtraBold"), local("Catamaran-ExtraBold"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jQhJVZNyBx2pqPA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* tamil */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Catamaran Black"), local("Catamaran-Black"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jZhNVctyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC; }

/* latin-ext */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Catamaran Black"), local("Catamaran-Black"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jZhNVatyBx2pqPIif.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Catamaran Black"), local("Catamaran-Black"), url(https://fonts.gstatic.com/s/catamaran/v6/o-0NIpQoyXQa2RxT7-5jZhNVZNyBx2pqPA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* Elements: Heading */
/* Elements: HR */
/* Elements: Image */
/* Elements: Link */
/* Elements: List */
/* Elements: Paragraph */
/* Elements: Selection */
/* Objects: Alert */
/* Components: Button */
/* Objects: FlexEmbed */
/* Objects: Form */
/* Objects: Grid */
/* Objects: Nav */
/* Objects: Structure */
/* Objects: Table */
/* Objects: Toggle */
/* Components: Fields */
/* Components: Cookie */
/* Components: Modal */
/* Components: DigitalPulse */
/* Functions: Throw */
/* Functions: Checks */
/* Functions: Truncate list */
/* Functions: Maps */
/* Functions: Maps */
/* Functions: Order index */
/* Functions: Units */
/* Functions: String */
/* Functions: SVG */
/* Mixin: Arrow */
/* Mixin: Background SVG */
/* Mixin: Caret */
/* Mixin: Context */
/* Mixin: Dimensions */
/* Mixin: Family */
/* Mixin: Font-responsive */
/* Mixin: Font-size */
/* Mixin: Font-smoothing */
/* Mixin: Grid */
/* Mixin: Image rendering */
/* Mixin: Last-children */
/* Mixin: Line-height crop */
/* Mixin: Maps */
/* Mixin: ParentNthStatus */
/* Mixin: ParentState */
/* Mixin: ParentStatus */
/* Mixin: Position */
/* Mixin: Maps */
/* Mixin: Transition */
/* Mixin: Truncate */
/* Reset */
*, *::before, *::after {
  box-sizing: inherit; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.375;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0; }

iframe {
  border: 0; }

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  display: block;
  width: 100%; }

option {
  font: inherit; }

textarea {
  resize: vertical; }

button {
  background: transparent;
  border: 0;
  padding: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

abbr[title],
dfn[title] {
  cursor: help; }

u,
ins {
  text-decoration: underline; }

img {
  font-style: italic; }

::selection {
  text-shadow: none; }

[tabindex="-1"]:focus {
  outline: none !important; }

/* HR */
hr {
  margin-top: 20px;
  margin-bottom: 18px;
  color: #0047A0;
  border: 0;
  border-bottom: 2px solid;
  width: 45px;
  display: block; }

/* Image */
img {
  max-width: 100%;
  height: auto; }

figure > img {
  display: block; }

/* Link */
a {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #F50057;
  text-decoration: none;
  font-weight: 700; }
  a:hover, a:focus, a:active {
    color: #F50057;
    text-decoration: underline; }

/* List */
ol,
ul {
  margin-bottom: 20px;
  padding-left: 29px; }
  ol li,
  ul li {
    margin-bottom: 12px; }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 4px;
    margin-bottom: 4px; }

ul {
  list-style-type: none; }
  ul li {
    position: relative; }
    ul li:not(.Nav-item)::before {
      content: "";
      height: 7px;
      width: 7px;
      background-color: #0047A0;
      position: absolute;
      left: -29px;
      border-radius: 50%;
      top: 6px; }

ul ul {
  list-style-type: none; }

ul ul ul {
  list-style-type: none; }

ol {
  padding-left: 19px;
  list-style-type: decimal; }
  ol li {
    padding-left: 20px; }

ol ol {
  padding-left: 38px;
  list-style-type: upper-alpha; }

ol ol ol {
  list-style-type: lower-roman; }

ol ol ol ol {
  list-style-type: lower-alpha; }

dt {
  font-weight: 700; }

/* Selection */
::selection {
  color: #ffffff;
  background-color: #F50057; }

/* Heading */
h1, .Alfa,
h2, .Bravo,
h3, .Charlie,
h4, .Delta,
h5, .Echo,
h6, .Foxtrot {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  max-width: 650px; }
  .fonts-loaded h1, .fonts-loaded .Alfa, .fonts-loaded
  h2, .fonts-loaded .Bravo, .fonts-loaded
  h3, .fonts-loaded .Charlie, .fonts-loaded
  h4, .fonts-loaded .Delta, .fonts-loaded
  h5, .fonts-loaded .Echo, .fonts-loaded
  h6, .fonts-loaded .Foxtrot {
    font-family: "Catamaran", sans-serif; }
  h1 small, .Alfa small,
  h2 small, .Bravo small,
  h3 small, .Charlie small,
  h4 small, .Delta small,
  h5 small, .Echo small,
  h6 small, .Foxtrot small {
    font-weight: normal; }

h1, .Alfa {
  margin-bottom: 22px;
  font-size: 55px;
  line-height: 1.1; }
  @media screen and (max-width: 47.9375em) {
    h1, .Alfa {
      margin-bottom: 22px;
      font-size: 35px;
      line-height: 1.2; } }

h2, .Bravo {
  margin-bottom: 22px;
  font-size: 45px;
  line-height: 1.1; }
  @media screen and (max-width: 47.9375em) {
    h2, .Bravo {
      margin-bottom: 22px;
      font-size: 30px;
      line-height: 1.2; } }

h3, .Charlie {
  margin-bottom: 22px;
  font-size: 25px;
  line-height: 1.2; }

h4, .Delta {
  margin-bottom: 22px;
  font-size: 25px;
  line-height: 1.2; }

h5, .Echo {
  margin-bottom: 22px;
  font-size: 20px; }

h6, .Foxtrot {
  margin-bottom: 22px;
  font-size: 18px;
  color: #0047A0; }

.Giga {
  margin-bottom: 22px;
  font-size: 96px;
  line-height: 1.1; }

.Mega {
  margin-bottom: 22px;
  font-size: 72px;
  line-height: 1.1; }

.Kilo {
  margin-bottom: 22px;
  font-size: 48px;
  line-height: 1.1; }

.Milli {
  margin-bottom: 22px;
  font-size: 12px; }

.Micro {
  margin-bottom: 22px;
  font-size: 10px; }

/* Paragraph */
p {
  margin-bottom: 20px;
  max-width: 800px; }
  p b, p strong {
    color: #0047A0; }

.Lead {
  font-size: 20px; }

/* Main */
html {
  background-color: #ffffff;
  color: #001B35;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100%; }
  html.fonts-loaded {
    font-family: "Catamaran", sans-serif; }
  html.hide-scroll {
    overflow: hidden; }

body {
  min-height: 100%; }

/* Alert */
.Alert {
  position: relative;
  margin-bottom: 20px;
  padding: 0.625em 0.83333em 0.625em 0.83333em;
  border: 1px solid;
  border-radius: 2px; }
  .Alert.is-dismissable {
    padding-right: 26.66667px; }
  .Alert p,
  .Alert ul {
    margin-bottom: 0; }
    .Alert p + p,
    .Alert p + ul,
    .Alert ul + p,
    .Alert ul + ul {
      margin-top: 20px; }
  .Alert a:not(.Button--close) {
    font-weight: bold; }
    .Alert a:not(.Button--close):hover {
      text-decoration: underline; }

/* Button */
/*
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Inherit font styles from ancestor.
 * 5. Normalize `line-height`. For `input`, it can't be changed from `normal`
 *    in Firefox 4+.
 * 6. Prevent button text from being selectable.
 * 7. Make sure `input` will wrap text across multiple lines.
 *
 */
.Button {
  appearance: none;
  background: transparent;
  border-color: transparent;
  border-radius: 29px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-size: 17px;
  line-height: inherit;
  margin: 0;
  padding: 0.625em 1.875em 0.625em 1.875em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: normal; }
  .Button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .Button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  .Button:active, .Button:focus, .Button:hover, .Button.is-active {
    color: inherit;
    text-decoration: none; }
  .Button:disabled, .Button.is-disabled {
    cursor: default;
    opacity: 0.6;
    pointer-events: none; }

/* FlexEmbed */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }
  .FlexEmbed::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%; }

.FlexEmbed-content,
.FlexEmbed-content > iframe,
.FlexEmbed-content > object,
.FlexEmbed-content > embed,
.FlexEmbed-content > video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

img.FlexEmbed-content {
  object-fit: cover; }

.FlexEmbed--1by1:before {
  padding-bottom: 100%; }

.FlexEmbed--2by1:before {
  padding-bottom: 50%; }

.FlexEmbed--3by1:before {
  padding-bottom: 33.33333%; }

.FlexEmbed--3by2:before {
  padding-bottom: 66.66667%; }

.FlexEmbed--4by3:before {
  padding-bottom: 75%; }

.FlexEmbed--16by9:before {
  padding-bottom: 56.25%; }

.FlexEmbed--map:before {
  padding-bottom: 34.64286%; }

/* Form */
.Form {
  margin-top: 40px; }
  .Form p {
    font-size: 14px;
    color: rgba(0, 27, 53, 0.5);
    padding-right: 20px; }

.Form-item:not(.Form-item--action) {
  margin-bottom: 20px;
  position: relative; }
  .Form-item:not(.Form-item--action) .Form-label {
    position: absolute;
    transform: translateY(12px) scale(1);
    transform-origin: top left;
    transition: transform .2s;
    z-index: 2; }
  .Form-item:not(.Form-item--action).is-active .Form-label {
    transform: translateY(-6px) scale(0.75);
    top: 0%; }

.Form-item--file {
  padding-top: 45px; }
  .Form-item--file .Form-label {
    transform: translateY(-37px) scale(1) !important; }
  .Form-item--file.is-active .Form-label {
    transform: none; }

.Form-label {
  display: inline-block;
  margin-bottom: 5px; }
  .Form-item:not(.is-required) .Form-label::after {
    content: ' - Optional';
    margin-left: 5px; }
    html[lang^="nl"] .Form-item:not(.is-required) .Form-label::after {
      content: ' - Optioneel'; }
    html[lang^="fr"] .Form-item:not(.is-required) .Form-label::after {
      content: ' - Facultatif'; }
  .Form-item.is-error .Form-label {
    color: #f44336; }
  .Form-item.is-success .Form-label {
    color: #4caf50; }

.Form-help {
  margin-top: 5px;
  color: #F50057;
  font-size: 12px; }
  .Form-help:first-child {
    margin-top: 0;
    margin-bottom: 10px; }
  .Form-help :last-child {
    margin-bottom: 0; }
  .Form-help + .Form-help {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e0e0e0; }

.Form-item.is-error .Form-help--validation {
  color: #f44336; }

.Form-item.is-success .Form-help--validation {
  color: #4caf50; }

.Form-controls {
  position: relative; }

@media screen and (min-width: 37.5em) {
  .Form--horizontal .Form-item {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start; }
  .Form--horizontal .Form-label {
    margin-top: 5px;
    margin-bottom: 0;
    padding-right: 20px;
    width: 33.33333%; }
  .Form--horizontal .Form-controls {
    width: 66.66667%; }
  .Form--horizontal .Form-item--action {
    padding-left: 33.33333%; } }

.Media-offscreen {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal; }

.Media-container {
  background: #000;
  box-sizing: border-box;
  font-family: 'Helvetica', Arial, serif;
  position: relative;
  text-align: left;
  text-indent: 0;
  vertical-align: top; }

.Media-container * {
  box-sizing: border-box; }

/* Hide native play button and control bar from iOS to favor plugin button */
.Media-container video::-webkit-media-controls,
.Media-container video::-webkit-media-controls-panel,
.Media-container video::-webkit-media-controls-panel-container,
.Media-container video::-webkit-media-controls-start-playback-button {
  -webkit-appearance: none;
  display: none !important; }

.Media-fill-container,
.Media-fill-container .Media-container {
  height: 100%;
  width: 100%; }

.Media-fill-container {
  background: transparent;
  margin: 0 auto;
  overflow: hidden;
  position: relative; }

.Media-container:focus {
  outline: none; }

.Media-iframe-overlay {
  height: 100%;
  position: absolute;
  width: 100%; }

.Media-embed,
.Media-embed body {
  background: #000;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.Media-fullscreen {
  overflow: hidden !important; }

.Media-container-fullscreen {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000; }

.Media-container-fullscreen .Media-mediaelement,
.Media-container-fullscreen video {
  height: 100% !important;
  width: 100% !important; }

/* Start: LAYERS */
.Media-background {
  left: 0;
  position: absolute;
  top: 0; }

.Media-mediaelement {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0; }

.Media-poster {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1; }

:root .Media-poster-img {
  display: none; }

.Media-poster-img {
  border: 0;
  padding: 0; }

.Media-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0; }

.Media-layer {
  z-index: 1; }

.Media-overlay-play {
  cursor: pointer; }

.Media-overlay-button {
  background: url("../img/media/controls.svg") no-repeat;
  background-position: 0 -39px;
  height: 80px;
  width: 80px; }

.Media-overlay:hover > .Media-overlay-button {
  background-position: -80px -39px; }

.Media-overlay-loading {
  height: 80px;
  width: 80px; }

.Media-overlay-loading-bg-img {
  animation: Media-loading-spinner 1s linear infinite;
  background: transparent url("../img/media/controls.svg") -160px -40px no-repeat;
  display: block;
  height: 80px;
  width: 80px;
  z-index: 1; }

@keyframes Media-loading-spinner {
  100% {
    transform: rotate(360deg); } }

/* End: LAYERS */
/* Start: CONTROL BAR */
.Media-controls {
  bottom: 0;
  display: flex;
  height: 40px;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0 10px;
  position: absolute;
  width: 100%;
  z-index: 3; }

.Media-controls:not([style*='display: none']) {
  background: rgba(255, 0, 0, 0.7);
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.35)); }

.Media-button,
.Media-time,
.Media-time-rail {
  font-size: 10px;
  height: 40px;
  line-height: 10px;
  margin: 0;
  width: 32px; }

.Media-button > button {
  background: transparent url("../img/media/controls.svg");
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  margin: 10px 6px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-decoration: none;
  width: 20px; }

/* :focus for accessibility */
.Media-button > button:focus {
  outline: dotted 1px #999; }

.Media-container-keyboard-inactive a,
.Media-container-keyboard-inactive a:focus,
.Media-container-keyboard-inactive button,
.Media-container-keyboard-inactive button:focus,
.Media-container-keyboard-inactive [role=slider],
.Media-container-keyboard-inactive [role=slider]:focus {
  outline: 0; }

/* End: CONTROL BAR */
/* Start: Time (Current / Duration) */
.Media-time {
  box-sizing: content-box;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  height: 24px;
  overflow: hidden;
  padding: 16px 6px 0;
  text-align: center;
  width: auto; }

/* End: Time (Current / Duration) */
/* Start: Play/Pause/Stop */
.Media-play > button {
  background-position: 0 0; }

.Media-pause > button {
  background-position: -20px 0; }

.Media-replay > button {
  background-position: -160px 0; }

/* End: Play/Pause/Stop */
/* Start: Progress Bar */
.Media-time-rail {
  direction: ltr;
  flex-grow: 1;
  height: 40px;
  margin: 0 10px;
  padding-top: 10px;
  position: relative; }

.Media-time-total,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-current,
.Media-time-float,
.Media-time-hovered,
.Media-time-float-current,
.Media-time-float-corner,
.Media-time-marker {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 10px;
  position: absolute; }

.Media-time-total {
  background: rgba(255, 255, 255, 0.3);
  margin: 5px 0 0;
  width: 100%; }

.Media-time-buffering {
  animation: buffering-stripes 2s linear infinite;
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
  background-size: 15px 15px;
  width: 100%; }

@keyframes buffering-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.Media-time-loaded {
  background: rgba(255, 255, 255, 0.3); }

.Media-time-current,
.Media-time-handle-content {
  background: rgba(255, 255, 255, 0.9); }

.Media-time-hovered {
  background: rgba(255, 255, 255, 0.5);
  z-index: 10; }

.Media-time-hovered.negative {
  background: rgba(0, 0, 0, 0.2); }

.Media-time-current,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-hovered {
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0.15s ease-in all;
  width: 100%; }

.Media-time-buffering {
  transform: scaleX(1); }

.Media-time-hovered {
  transition: height 0.1s cubic-bezier(0.44, 0, 1, 1); }

.Media-time-hovered.no-hover {
  transform: scaleX(0) !important; }

.Media-time-handle,
.Media-time-handle-content {
  border: 4px solid transparent;
  cursor: pointer;
  left: 0;
  position: absolute;
  transform: translateX(0);
  z-index: 11; }

.Media-time-handle-content {
  border: 4px solid rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  height: 10px;
  left: -7px;
  top: -4px;
  transform: scale(0);
  width: 10px; }

.Media-time-rail:hover .Media-time-handle-content,
.Media-time-rail .Media-time-handle-content:focus,
.Media-time-rail .Media-time-handle-content:active {
  transform: scale(1); }

.Media-time-float {
  background: #eee;
  border: solid 1px #333;
  bottom: 100%;
  color: #111;
  display: none;
  height: 17px;
  margin-bottom: 9px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 36px; }

.Media-time-float-current {
  display: block;
  left: 0;
  margin: 2px;
  text-align: center;
  width: 30px; }

.Media-time-float-corner {
  border: solid 5px #eee;
  border-color: #eee transparent transparent;
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  line-height: 0;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 0; }

.Media-long-video .Media-time-float {
  margin-left: -23px;
  width: 64px; }

.Media-long-video .Media-time-float-current {
  width: 60px; }

.Media-broadcast {
  color: #fff;
  height: 10px;
  position: absolute;
  top: 15px;
  width: 100%; }

/* End: Progress Bar */
/* Start: Fullscreen */
.Media-fullscreen-button > button {
  background-position: -80px 0; }

.Media-unfullscreen > button {
  background-position: -100px 0; }

/* End: Fullscreen */
/* Start: Mute/Volume */
.Media-mute > button {
  background-position: -60px 0; }

.Media-unmute > button {
  background-position: -40px 0; }

.Media-volume-button {
  position: relative; }

.Media-volume-button > .Media-volume-slider {
  -webkit-backface-visibility: hidden;
  background: rgba(50, 50, 50, 0.7);
  border-radius: 0;
  bottom: 100%;
  display: none;
  height: 115px;
  left: 50%;
  margin: 0;
  position: absolute;
  transform: translateX(-50%);
  width: 25px;
  z-index: 1; }

.Media-volume-button:hover {
  border-radius: 0 0 4px 4px; }

.Media-volume-total {
  background: rgba(255, 255, 255, 0.5);
  height: 100px;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 8px;
  transform: translateX(-50%);
  width: 2px; }

.Media-volume-current {
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  margin: 0;
  position: absolute;
  width: 100%; }

.Media-volume-handle {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1px;
  cursor: ns-resize;
  height: 6px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 16px; }

.Media-horizontal-volume-slider {
  display: block;
  height: 36px;
  position: relative;
  vertical-align: middle;
  width: 56px; }

.Media-horizontal-volume-total {
  background: rgba(50, 50, 50, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 8px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 16px;
  width: 50px; }

.Media-horizontal-volume-current {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.Media-horizontal-volume-handle {
  display: none; }

/* End: Mute/Volume */
/* Start: Track (Captions and Chapters) */
.Media-captions-button,
.Media-chapters-button {
  position: relative; }

.Media-captions-button > button {
  background-position: -140px 0; }

.Media-chapters-button > button {
  background-position: -180px 0; }

.Media-captions-button > .Media-captions-selector,
.Media-chapters-button > .Media-chapters-selector {
  background: rgba(50, 50, 50, 0.7);
  border: solid 1px transparent;
  border-radius: 0;
  bottom: 100%;
  margin-right: -43px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 50%;
  visibility: visible;
  width: 86px; }

.Media-chapters-button > .Media-chapters-selector {
  margin-right: -55px;
  width: 110px; }

.Media-captions-selector-list,
.Media-chapters-selector-list {
  list-style-type: none !important;
  margin: 0;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item,
.Media-chapters-selector-list-item {
  color: #fff;
  cursor: pointer;
  display: block;
  list-style-type: none !important;
  margin: 0 0 6px;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item:hover,
.Media-chapters-selector-list-item:hover {
  background-color: #c8c8c8 !important;
  background-color: rgba(255, 255, 255, 0.4) !important; }

.Media-captions-selector-input,
.Media-chapters-selector-input {
  clear: both;
  float: left;
  left: -1000px;
  margin: 3px 3px 0 5px;
  position: absolute; }

.Media-captions-selector-label,
.Media-chapters-selector-label {
  cursor: pointer;
  float: left;
  font-size: 10px;
  line-height: 15px;
  padding: 4px 10px 0;
  width: 100%; }

.Media-captions-selected,
.Media-chapters-selected {
  color: #21f8f8; }

.Media-captions-translations {
  font-size: 10px;
  margin: 0 0 5px; }

.Media-captions-layer {
  bottom: 0;
  color: #fff;
  font-size: 16px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center; }

.Media-captions-layer a {
  color: #fff;
  text-decoration: underline; }

.Media-captions-layer[lang=ar] {
  font-size: 20px;
  font-weight: normal; }

.Media-captions-position {
  bottom: 15px;
  left: 0;
  position: absolute;
  width: 100%; }

.Media-captions-position-hover {
  bottom: 35px; }

.Media-captions-text,
.Media-captions-text * {
  background: rgba(20, 20, 20, 0.5);
  box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
  padding: 0;
  white-space: pre-wrap; }

.Media-container.Media-hide-cues video::-webkit-media-text-track-container {
  display: none; }

/* End: Track (Captions and Chapters) */
/* Start: Error */
.Media-overlay-error {
  position: relative; }

.Media-overlay-error > img {
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  z-index: -1; }

.Media-cannotplay,
.Media-cannotplay a {
  color: #fff;
  font-size: 0.8em; }

.Media-cannotplay {
  position: relative; }

.Media-cannotplay p,
.Media-cannotplay a {
  display: inline-block;
  padding: 0 15px;
  width: 100%; }

.Media-link {
  position: relative;
  display: block; }
  .Media-link:hover:after {
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: linear;
    opacity: 0; }
  .Media-link:after {
    content: "";
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: linear;
    background-color: #2F2F2F;
    opacity: 0.85;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1; }
  .Media-link .Play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; }

/* Grid */
/**
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */
.Grid {
  box-sizing: border-box;
  display: flex;
  /* 1 */
  flex-flow: row wrap;
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */ }

/**
    * No explicit width by default. Rely on combining `Grid-cell` with a dimension
    * utility or a component class that extends 'Grid'.
    *
    * 1. Set flex items to full width by default
    * 2. Fix issue where elements with overflow extend past the
    *      `Grid-cell` container - https://git.io/vw5oF
    */
.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%;
  /* 1 */
  min-width: 0;
  /* 2 */ }

/**
    * Modifiers
    */
.Grid--alignCenter {
  justify-content: center; }

.Grid--alignRight {
  justify-content: flex-end; }

.Grid--alignMiddle {
  align-items: center; }

.Grid--alignBottom {
  align-items: flex-end; }

.Grid--fill > .Grid-cell {
  flex: 1 1 0%;
  /* 1 */ }

.Grid--fit > .Grid-cell {
  flex-basis: auto; }

.Grid--equalHeight > .Grid-cell {
  display: flex; }

.Grid--withExtraSmallGutter {
  margin-left: -5px; }
  .Grid--withExtraSmallGutter > .Grid-cell {
    padding-left: 5px; }

.Grid--withSmallGutter {
  margin-left: -10px; }
  .Grid--withSmallGutter > .Grid-cell {
    padding-left: 10px; }

.Grid--withGutter {
  margin-left: -20px; }
  .Grid--withGutter > .Grid-cell {
    padding-left: 20px; }

.Grid--withLargeGutter {
  margin-left: -40px; }
  .Grid--withLargeGutter > .Grid-cell {
    padding-left: 40px; }

.Grid--withExtraLargeGutter {
  margin-left: -80px; }
  .Grid--withExtraLargeGutter > .Grid-cell {
    padding-left: 80px; }

.Grid--withCustomSmallGutter {
  margin-left: -15px; }
  .Grid--withCustomSmallGutter > .Grid-cell {
    padding-left: 15px; }

.Grid--withCustomGutter {
  margin-left: -35px; }
  .Grid--withCustomGutter > .Grid-cell {
    padding-left: 35px; }

.Grid--withCustomLargeGutter {
  margin-left: -95px; }
  .Grid--withCustomLargeGutter > .Grid-cell {
    padding-left: 95px; }

/* Icon */
.Icon {
  display: inline-flex;
  align-self: center;
  flex-shrink: 0; }
  .Icon svg {
    fill: currentColor;
    height: 1em;
    width: 1em; }
  .Icon.Icon--baseline svg {
    top: 0.125em;
    position: relative; }

/* Nav */
.Nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto; }

.Nav-item {
  margin: 0; }

.Nav-link {
  user-select: none;
  display: block; }
  .Nav-link, .Nav-link:active, .Nav-link:focus, .Nav-link:hover {
    text-decoration: none; }

.Nav--stacked .Nav-list {
  flex-direction: column;
  flex-wrap: nowrap; }

.Nav--fit:not(.Nav--stacked) .Nav-item {
  flex: 1 1 auto; }

.Nav--alignCenter {
  text-align: center; }
  .Nav--alignCenter:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: center; }

.Nav--alignRight {
  text-align: right; }
  .Nav--alignRight:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: flex-end; }

/* Structure */
html {
  background-color: #F7F7F7; }

.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  width: 100%; }
  .Container--xl {
    max-width: 1480px; }
  .Container--lg {
    max-width: 1320px; }
  .Container--md {
    max-width: 1440px; }
  .Container--sm {
    max-width: 1000px; }
  .Container--xs {
    max-width: 640px; }
  @media screen and (min-width: 48em) {
    .Container {
      max-width: 1480px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xl {
        max-width: 1520px; }
      .Container--lg {
        max-width: 1360px; }
      .Container--md {
        max-width: 1480px; }
      .Container--sm {
        max-width: 1040px; }
      .Container--xs {
        max-width: 680px; } }

body {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer"; }

.Header {
  position: relative;
  z-index: 3;
  grid-area: header;
  height: 180px;
  padding-top: 60px;
  padding-bottom: 80px; }
  @media screen and (max-width: 63.9375em) {
    .Header {
      height: 80px; } }
  .Header > .Container {
    height: 100%; }

.Footer {
  position: relative;
  z-index: 1;
  grid-area: footer;
  background-color: #F7F7F7; }
  .Footer > .Container {
    height: 100%; }

.Main {
  position: relative;
  z-index: 2;
  grid-area: main;
  background-color: #F7F7F7; }
  .Main > .Container {
    padding-top: 40px;
    padding-bottom: 20px; }
    @media screen and (min-width: 48em) {
      .Main > .Container {
        padding-top: 20px;
        grid-gap: 40px; }
        .Main > .Container.has-leftSide {
          display: grid;
          grid-template-columns: 33.33333% 1fr;
          grid-template-rows: auto;
          grid-template-areas: "left content"; }
        .Main > .Container.has-rightSide {
          display: grid;
          grid-template-columns: 1fr 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "content right"; }
        .Main > .Container.has-sides {
          display: grid;
          grid-template-columns: 33.33333% auto 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "left content right"; } }

.Side {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .Side {
      margin-top: 20px; } }
  @media screen and (min-width: 48em) {
    .Side--left {
      grid-area: left; }
    .Side--right {
      grid-area: right; } }

.Content {
  grid-area: content;
  position: relative; }

/* Table */
.Table {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .Table th,
  .Table td {
    vertical-align: middle;
    padding: 3.33333px 6.66667px 3.33333px 6.66667px; }
  .Table thead {
    background-color: #f5f5f5;
    border-bottom: 2px solid #e0e0e0; }
  .Table tbody tr + tr {
    border-top: 1px solid #e0e0e0; }
  .Table tfoot {
    background-color: #f5f5f5;
    border-top: 2px solid #e0e0e0; }
  @media screen and (max-width: 63.9375em) {
    .Table.is-responsive {
      border-width: 0; }
      .Table.is-responsive,
      .Table.is-responsive tbody,
      .Table.is-responsive tfoot,
      .Table.is-responsive tr {
        display: block; }
      .Table.is-responsive thead {
        display: none; }
      .Table.is-responsive tbody tr {
        border: 1px solid #e0e0e0;
        border-bottom-width: 0; }
      .Table.is-responsive tfoot {
        border: 1px solid #e0e0e0;
        border-top-width: 2px; }
      .Table.is-responsive .Table-cell {
        display: inline-block;
        vertical-align: top;
        width: 100%; }
        .Table.is-responsive .Table-cell[data-table-label]::before {
          content: attr(data-table-label);
          display: block;
          margin: -10px -10px 10px;
          padding: 10px;
          background-color: #fafafa;
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0; } }

.Table--striped tbody tr:nth-child(even) {
  background-color: #fafafa; }

@media screen and (max-width: 63.9375em) {
  .Table--striped.is-responsive tbody tr:nth-child(even) {
    background-color: transparent; } }

.Toggle {
  height: 24px;
  width: 30px;
  position: relative;
  cursor: pointer;
  transform: rotate(0deg); }
  .Toggle-item {
    height: 2px;
    width: 100%;
    position: absolute;
    transition-property: top, transform, opacity;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    background-color: #0047A0;
    border-radius: 0px;
    display: block;
    transform: rotate(0deg); }
    .Toggle-item:nth-child(1) {
      top: 0; }
      .show-nav .Toggle-item:nth-child(1) {
        top: 11px;
        transform: rotate(135deg); }
    .Toggle-item:nth-child(2) {
      top: 11px; }
      .show-nav .Toggle-item:nth-child(2) {
        opacity: 0; }
    .Toggle-item:nth-child(3) {
      top: 22px; }
      .show-nav .Toggle-item:nth-child(3) {
        top: 11px;
        transform: rotate(-135deg); }

/* Alert */
.Alert--success {
  color: #659667;
  background-color: #eef6ef;
  border-color: #dfeddf; }
  .Alert--success a {
    color: #4c714d; }

.Alert--info {
  color: #5690bf;
  background-color: #ebf4fb;
  border-color: #d7e9f7; }
  .Alert--info a {
    color: #396d96; }

.Alert--warning {
  color: #bf8c40;
  background-color: #fcf4e9;
  border-color: #f9ead3; }
  .Alert--warning a {
    color: #8f6930; }

.Alert--danger {
  color: #c56c66;
  background-color: #fceeed;
  border-color: #f8dbd9; }
  .Alert--danger a {
    color: #a2453e; }

/* Button */
@keyframes arrow {
  0% {
    transform: translate(0); }
  50% {
    transform: translate(-4px); }
  to {
    transform: translate(0); } }

.Button .Icon-arrow path {
  transition-property: fill;
  transition-duration: 200ms;
  transition-timing-function: linear; }

.Button:active .Icon-arrow, .Button:focus .Icon-arrow, .Button:hover .Icon-arrow, .Button.is-hovered .Icon-arrow, .Button.is-active .Icon-arrow {
  animation: arrow .8s infinite;
  animation-duration: 0.8s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: arrow; }

.Button--primary {
  background-color: #0047A0;
  color: #ffffff;
  font-weight: 500; }
  .Button--primary:not(.Button--outline) .Icon-arrow path {
    fill: #ffffff; }
  .Button--primary:not(.Button--outline):active, .Button--primary:not(.Button--outline):focus, .Button--primary:not(.Button--outline):hover, .Button--primary:not(.Button--outline).is-hovered, .Button--primary:not(.Button--outline).is-active {
    color: #ffffff;
    background-color: #001B35; }
  .Button--primary.Button--outline {
    color: #0047A0; }
    .Button--primary.Button--outline .Icon-arrow path {
      fill: #0047A0; }
    .Button--primary.Button--outline:active, .Button--primary.Button--outline:focus, .Button--primary.Button--outline:hover, .Button--primary.Button--outline.is-hovered, .Button--primary.Button--outline.is-active {
      background-color: #0047A0;
      color: #ffffff;
      border-color: #0047A0; }
      .Button--primary.Button--outline:active .Icon-arrow path, .Button--primary.Button--outline:focus .Icon-arrow path, .Button--primary.Button--outline:hover .Icon-arrow path, .Button--primary.Button--outline.is-hovered .Icon-arrow path, .Button--primary.Button--outline.is-active .Icon-arrow path {
        fill: #ffffff; }

.Button--secondary {
  background-color: #0047A0;
  color: #ffffff;
  border-radius: 0;
  padding: 25px;
  font-size: 20px;
  font-weight: 700; }
  .Button--secondary:not(.Button--outline) .Icon-arrow path {
    fill: #ffffff; }
  .Button--secondary:not(.Button--outline):active, .Button--secondary:not(.Button--outline):focus, .Button--secondary:not(.Button--outline):hover, .Button--secondary:not(.Button--outline).is-hovered, .Button--secondary:not(.Button--outline).is-active {
    color: #ffffff;
    background-color: #001B35; }
  .Button--secondary.Button--outline {
    color: #0047A0; }
    .Button--secondary.Button--outline .Icon-arrow path {
      fill: #0047A0; }
    .Button--secondary.Button--outline:active, .Button--secondary.Button--outline:focus, .Button--secondary.Button--outline:hover, .Button--secondary.Button--outline.is-hovered, .Button--secondary.Button--outline.is-active {
      background-color: #0047A0;
      color: #ffffff; }
      .Button--secondary.Button--outline:active .Icon-arrow path, .Button--secondary.Button--outline:focus .Icon-arrow path, .Button--secondary.Button--outline:hover .Icon-arrow path, .Button--secondary.Button--outline.is-hovered .Icon-arrow path, .Button--secondary.Button--outline.is-active .Icon-arrow path {
        fill: #ffffff; }

.Button--tertiary {
  background-color: transparent;
  color: #001B35;
  font-weight: 500;
  border-radius: 0;
  padding: 15px 0px;
  border-bottom: 1px solid #0047A0;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 18px; }
  .Button--tertiary .Icon-arrow path {
    fill: #0047A0; }
  .Button--tertiary:active, .Button--tertiary:focus, .Button--tertiary:hover, .Button--tertiary.is-hovered, .Button--tertiary.is-active {
    color: #0047A0; }
    .Button--tertiary:active .Icon-arrow path, .Button--tertiary:focus .Icon-arrow path, .Button--tertiary:hover .Icon-arrow path, .Button--tertiary.is-hovered .Icon-arrow path, .Button--tertiary.is-active .Icon-arrow path {
      fill: #0047A0; }

.Button--info {
  background-color: #2196f3;
  color: #ffffff; }
  .Button--info:not(.Button--outline):active, .Button--info:not(.Button--outline):focus, .Button--info:not(.Button--outline):hover, .Button--info:not(.Button--outline).is-hovered, .Button--info:not(.Button--outline).is-active {
    background-color: #1976d2;
    color: #ffffff; }
  .Button--info.Button--outline {
    color: #2196f3; }
    .Button--info.Button--outline:active, .Button--info.Button--outline:focus, .Button--info.Button--outline:hover, .Button--info.Button--outline.is-hovered, .Button--info.Button--outline.is-active {
      color: #1976d2; }

.Button--success {
  background-color: #4caf50;
  color: #ffffff; }
  .Button--success:not(.Button--outline):active, .Button--success:not(.Button--outline):focus, .Button--success:not(.Button--outline):hover, .Button--success:not(.Button--outline).is-hovered, .Button--success:not(.Button--outline).is-active {
    background-color: #388e3c;
    color: #ffffff; }
  .Button--success.Button--outline {
    color: #4caf50; }
    .Button--success.Button--outline:active, .Button--success.Button--outline:focus, .Button--success.Button--outline:hover, .Button--success.Button--outline.is-hovered, .Button--success.Button--outline.is-active {
      color: #388e3c; }

.Button--warning {
  background-color: #ff9800;
  color: #ffffff; }
  .Button--warning:not(.Button--outline):active, .Button--warning:not(.Button--outline):focus, .Button--warning:not(.Button--outline):hover, .Button--warning:not(.Button--outline).is-hovered, .Button--warning:not(.Button--outline).is-active {
    background-color: #f57c00;
    color: #ffffff; }
  .Button--warning.Button--outline {
    color: #ff9800; }
    .Button--warning.Button--outline:active, .Button--warning.Button--outline:focus, .Button--warning.Button--outline:hover, .Button--warning.Button--outline.is-hovered, .Button--warning.Button--outline.is-active {
      color: #f57c00; }

.Button--danger {
  background-color: #f44336;
  color: #ffffff; }
  .Button--danger:not(.Button--outline):active, .Button--danger:not(.Button--outline):focus, .Button--danger:not(.Button--outline):hover, .Button--danger:not(.Button--outline).is-hovered, .Button--danger:not(.Button--outline).is-active {
    background-color: #d32f2f;
    color: #ffffff; }
  .Button--danger.Button--outline {
    color: #f44336; }
    .Button--danger.Button--outline:active, .Button--danger.Button--outline:focus, .Button--danger.Button--outline:hover, .Button--danger.Button--outline.is-hovered, .Button--danger.Button--outline.is-active {
      color: #d32f2f; }

.Button--outline {
  background-color: transparent;
  border-color: currentColor; }

.Button--large {
  font-size: 21.25px;
  padding-right: 60px;
  padding-left: 60px; }

.Button--small {
  font-size: 12.75px;
  padding-right: 20px;
  padding-left: 20px; }

.Button--full {
  display: block;
  width: 100%; }

.Button--bare {
  border: 0;
  border-radius: 0;
  padding: 0;
  color: rgba(0, 27, 53, 0.5);
  justify-content: flex-start; }
  .Button--bare:active, .Button--bare:focus, .Button--bare:hover, .Button--bare.is-hovered, .Button--bare.is-active {
    color: #001B35; }
    .Button--bare:active .Icon-arrow-bare circle, .Button--bare:focus .Icon-arrow-bare circle, .Button--bare:hover .Icon-arrow-bare circle, .Button--bare.is-hovered .Icon-arrow-bare circle, .Button--bare.is-active .Icon-arrow-bare circle {
      fill: #001B35; }
    .Button--bare:active .Icon-arrow-circle-outline path, .Button--bare:focus .Icon-arrow-circle-outline path, .Button--bare:hover .Icon-arrow-circle-outline path, .Button--bare.is-hovered .Icon-arrow-circle-outline path, .Button--bare.is-active .Icon-arrow-circle-outline path {
      fill: #ffffff;
      transition-property: fill;
      transition-duration: 200ms;
      transition-timing-function: linear; }
    .Button--bare:active .Icon-arrow-circle-outline circle, .Button--bare:focus .Icon-arrow-circle-outline circle, .Button--bare:hover .Icon-arrow-circle-outline circle, .Button--bare.is-hovered .Icon-arrow-circle-outline circle, .Button--bare.is-active .Icon-arrow-circle-outline circle {
      fill: #001B35;
      stroke: transparent;
      transition-property: stroke, fill;
      transition-duration: 200ms;
      transition-timing-function: linear; }
    .Button--bare:active .Icon-arrow-circle-outline g, .Button--bare:focus .Icon-arrow-circle-outline g, .Button--bare:hover .Icon-arrow-circle-outline g, .Button--bare.is-hovered .Icon-arrow-circle-outline g, .Button--bare.is-active .Icon-arrow-circle-outline g {
      opacity: 1;
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: linear; }
  .Button--bare .Icon-arrow-bare circle {
    transition-property: fill;
    transition-duration: 200ms;
    transition-timing-function: linear;
    fill: rgba(0, 27, 53, 0.5); }
  .Button--bare .Icon-arrow-bare path {
    transition-property: stroke;
    transition-duration: 200ms;
    transition-timing-function: linear;
    stroke: #FFF; }
  .Button--bare .Icon-arrow-circle-outline circle {
    transition-property: stroke, fill;
    transition-duration: 200ms;
    transition-timing-function: linear;
    fill: transparent; }
  .Button--bare .Icon-arrow-circle-outline path {
    transition-property: stroke, fill;
    transition-duration: 200ms;
    transition-timing-function: linear;
    stroke: transparent; }

.Modal {
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10; }
  .Modal, .Modal:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .Modal::before {
    background-color: rgba(0, 0, 0, 0.5);
    content: '';
    z-index: -1; }
  .Modal:not(.is-active) {
    opacity: 0;
    visibility: hidden; }
  .Modal-inner {
    display: flex;
    align-items: center;
    min-height: 100%; }
  .Modal-innerBody {
    background-color: #ffffff;
    border-radius: 0;
    border: 1px solid #F7F7F7;
    border-color: #F7F7F7;
    margin: 20px 20px 20px 20px;
    width: auto; }
    @media screen and (min-width: 37.5em) {
      .Modal-innerBody {
        margin: 40px auto;
        max-width: 600px;
        width: 100%; } }
  .Modal-header {
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 20px 20px 20px; }
  .Modal-title {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 0; }
  .Modal-close {
    font-size: 24px;
    line-height: 1.2;
    transition-property: opacity;
    transition-duration: visibility;
    transition-timing-function: linear;
    cursor: pointer;
    font-weight: 700;
    line-height: 1;
    padding: 20px 20px 20px 20px;
    margin: -20px -20px -20px auto; }
    html:not(.is-tabbing) .Modal-close:focus {
      outline: 0; }
    .Modal-close:not(:hover) {
      opacity: .5; }
  .Modal-body {
    padding: 1.25em 1.25em 1.25em 1.25em; }
  .Modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 1.25em 1.25em 1.25em 1.25em;
    border-top: 1px solid #e0e0e0; }

/* Outdated Browser */
.Outdated {
  background-color: #f44336;
  color: #ffffff;
  font-size: 12px;
  padding: 12px 24px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1500; }
  .Outdated-title {
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 6px; }
  .Outdated-text {
    margin-bottom: 0;
    line-height: 1; }
  .Outdated-button {
    border: 2px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-weight: 700;
    line-height: 1;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    width: 230px;
    /*need for IE*/
    position: absolute;
    top: 12px;
    right: 24px; }
    .Outdated-button:hover {
      background-color: #ffffff;
      color: #f44336; }
  * html .Outdated {
    position: absolute; }

/* Cookie */
.CookieConsent {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  background-color: rgba(0, 27, 53, 0.95);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .CookieConsent:not(.is-active) {
    opacity: 0;
    visibility: hidden; }
  .CookieConsent-inner {
    background-color: #ffffff;
    color: #001B35;
    padding: 40px;
    max-width: 700px;
    max-height: 90%;
    width: 90%;
    overflow-y: auto; }
  .CookieConsent a {
    font-weight: 600; }
    .CookieConsent a, .CookieConsent a:active, .CookieConsent a:focus, .CookieConsent a:hover {
      color: inherit; }
  .CookieConsent-close {
    cursor: pointer;
    display: block;
    flex-shrink: 0;
    font-size: 150%;
    margin-left: 20px;
    line-height: 1;
    transition-property: opacity;
    transition-duration: 200ms; }
    .CookieConsent-close:not(:hover) {
      opacity: .75; }

.CookieGroups .CookieItems .Table {
  border-spacing: 0 !important; }
  @media screen and (max-width: 48em) {
    .CookieGroups .CookieItems .Table tr {
      margin-bottom: 20px; }
    .CookieGroups .CookieItems .Table tr,
    .CookieGroups .CookieItems .Table td {
      border: none !important; } }
  .CookieGroups .CookieItems .Table-cell {
    border: none;
    margin-bottom: 15px;
    font-size: 16px; }
    .CookieGroups .CookieItems .Table-cell:first-child {
      hyphens: auto; }
    @media screen and (max-width: 48em) {
      .CookieGroups .CookieItems .Table-cell::before {
        background-color: #F7F7F7 !important;
        border: none !important;
        font-weight: bold; }
      .CookieGroups .CookieItems .Table-cell:first-child::before {
        background-color: #001B35 !important;
        color: #ffffff; } }
  .CookieGroups .CookieItems .Table thead {
    color: #ffffff;
    background-color: #001B35; }
    .CookieGroups .CookieItems .Table thead th {
      padding: 10px 20px; }
      @media screen and (max-width: 47.9375em) {
        .CookieGroups .CookieItems .Table thead th {
          padding: 10px 10px; } }
  .CookieGroups .CookieItems .Table tbody tr td {
    padding: 10px 20px;
    border-bottom: 1px solid rgba(0, 27, 53, 0.25); }
    @media screen and (max-width: 47.9375em) {
      .CookieGroups .CookieItems .Table tbody tr td {
        padding: 10px 10px; } }
  .CookieGroups .CookieItems .Table tbody tr:last-child td {
    border-bottom: none; }

.cookie-though {
  --ct-primary-400: #0047A0; }

/* Header */
.Header {
  background-color: #F7F7F7;
  padding-top: 20px;
  padding-bottom: 20px; }
  .Header > .Container {
    display: flex;
    align-items: center; }

html.show-nav .Header {
  top: 0px;
  position: fixed;
  width: 100%; }
  html.show-nav .Header--sticky {
    will-change: unset;
    transition: transform 200ms linear;
    position: fixed; }
    html.show-nav .Header--sticky.is-pinned {
      transform: none; }
    html.show-nav .Header--sticky.is-unpinned {
      transform: none; }

html:not(.show-nav) .Header--sticky {
  will-change: transform;
  transition: transform 200ms linear;
  position: fixed;
  width: 100vw;
  box-shadow: none; }
  html:not(.show-nav) .Header--sticky.is-pinned {
    transform: translateY(0%); }
  html:not(.show-nav) .Header--sticky.is-unpinned {
    transform: translateY(-100%); }

html:not(.show-nav) .Header.is-notTop {
  box-shadow: 0px 5px 30px rgba(0, 27, 53, 0.1); }

html:not(.show-nav) .Header.is-top + .Main {
  margin-top: 180px;
  transition-property: margin-top;
  transition-duration: 200ms;
  transition-timing-function: linear; }
  @media screen and (max-width: 63.9375em) {
    html:not(.show-nav) .Header.is-top + .Main {
      margin-top: 80px; } }

/* Navigation */
.Navigation-toggle {
  display: block; }

.Navigation-body {
  display: flex;
  margin-left: -20px; }
  .Navigation-body > .Nav {
    padding-left: 20px; }
  @media screen and (max-width: 63.9375em) {
    .Navigation-body {
      position: fixed;
      top: 60px;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      transition-property: opacity, visibility;
      transition-duration: 200ms;
      transition-timing-function: linear;
      background-color: #F7F7F7;
      flex-direction: column;
      padding: 20px;
      min-height: calc(100vh - 80px); }
      @supports (-webkit-touch-callout: none) {
        .Navigation-body {
          min-height: calc(100vh - 190px); } }
      html:not(.show-nav) .Navigation-body {
        opacity: 0;
        visibility: hidden; } }

/* Types */
/* Main */
@media screen and (max-width: 63.9375em) {
  .Nav--main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1; } }

.Nav--main .Nav-list {
  margin-left: -20px; }
  @media screen and (max-width: 63.9375em) {
    .Nav--main .Nav-list {
      flex-direction: column;
      align-items: center;
      justify-content: center; } }

.Nav--main .Nav-item {
  margin-left: 30px; }
  .Nav--main .Nav-item:not(:hover) .Nav-dropdown {
    opacity: 0;
    visibility: hidden; }
  @media screen and (min-width: 64em) {
    .Nav--main .Nav-item {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center; }
      .Nav--main .Nav-item .Caret {
        height: 4px;
        width: 8px;
        position: relative;
        position: absolute;
        margin-left: 5px;
        top: 8px;
        right: -15px; }
        .Nav--main .Nav-item .Caret:before, .Nav--main .Nav-item .Caret:after {
          content: "";
          position: absolute; }
        .Nav--main .Nav-item .Caret:before {
          top: 0;
          left: 0;
          border-top: 4px solid currentColor;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent; }
        .Nav--main .Nav-item .Caret:after {
          left: 1px;
          top: 0;
          border-top: 3px solid #F7F7F7;
          border-left: 3px solid transparent;
          border-right: 3px solid transparent; }
      .Nav--main .Nav-item .Nav-dropdown {
        transition-property: opacity, visibility;
        transition-duration: 200ms;
        transition-timing-function: linear;
        position: absolute;
        top: 100%;
        left: calc(50% + 40px);
        transform: translateX(-50%);
        color: #000000;
        background-color: #ffffff;
        margin-top: 10px;
        -webkit-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
        width: 720px;
        padding: 40px 60px; }
        .Nav--main .Nav-item .Nav-dropdown.Grid--withLargeGutter > .Grid-cell:first-of-type {
          padding-left: 0px; }
        .Nav--main .Nav-item .Nav-dropdown::before {
          content: "";
          width: 100%;
          background-color: transparent;
          height: 20px;
          top: -20px;
          left: 0;
          position: absolute; }
        .Nav--main .Nav-item .Nav-dropdown .u-linkClean.is-active, .Nav--main .Nav-item .Nav-dropdown .u-linkClean:active, .Nav--main .Nav-item .Nav-dropdown .u-linkClean:focus, .Nav--main .Nav-item .Nav-dropdown .u-linkClean:hover {
          color: #F50057; } }
  @media screen and (max-width: 80em) {
    .Nav--main .Nav-item {
      margin-left: 20px; } }
  @media screen and (max-width: 63.9375em) {
    .Nav--main .Nav-item {
      margin-left: 0px; }
      .Nav--main .Nav-item + .Nav-item {
        margin-top: 15px; }
      .show-nav .Nav--main .Nav-item {
        transition-property: opacity, transform;
        transition-duration: 300ms, 1s;
        transition-timing-function: linear, cubic-bezier(0.19, 1, 0.22, 1); }
        .show-nav .Nav--main .Nav-item:nth-child(1) {
          transition-delay: 0.02s; }
        .show-nav .Nav--main .Nav-item:nth-child(2) {
          transition-delay: 0.04s; }
        .show-nav .Nav--main .Nav-item:nth-child(3) {
          transition-delay: 0.06s; }
        .show-nav .Nav--main .Nav-item:nth-child(4) {
          transition-delay: 0.08s; }
        .show-nav .Nav--main .Nav-item:nth-child(5) {
          transition-delay: 0.1s; }
        .show-nav .Nav--main .Nav-item:nth-child(6) {
          transition-delay: 0.12s; }
        .show-nav .Nav--main .Nav-item:nth-child(7) {
          transition-delay: 0.14s; }
        .show-nav .Nav--main .Nav-item:nth-child(8) {
          transition-delay: 0.16s; }
        .show-nav .Nav--main .Nav-item:nth-child(9) {
          transition-delay: 0.18s; }
        .show-nav .Nav--main .Nav-item:nth-child(10) {
          transition-delay: 0.2s; }
      html:not(.show-nav) .Nav--main .Nav-item {
        opacity: 0;
        transform: translateY(20px); }
      .Nav--main .Nav-item .Nav-dropdown {
        display: none; } }

.Nav--main .Nav-link {
  font-weight: 500;
  color: #0047A0;
  padding-left: 10px;
  position: relative; }
  .Nav--main .Nav-link::before {
    content: "";
    background-color: rgba(0, 71, 160, 0.2);
    height: 34px;
    width: 34px;
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: linear; }
  .Nav--main .Nav-link.is-active, .Nav--main .Nav-link:active, .Nav--main .Nav-link:focus, .Nav--main .Nav-link:hover {
    color: #001B35;
    font-weight: 500; }
    .Nav--main .Nav-link.is-active::before, .Nav--main .Nav-link:active::before, .Nav--main .Nav-link:focus::before, .Nav--main .Nav-link:hover::before {
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: linear;
      opacity: 1; }

/* Lang */
@media screen and (min-width: 64em) {
  .Nav--lang {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center; }
    .Nav--lang:not(:hover) .Nav-list {
      opacity: 0;
      visibility: hidden; } }

@media screen and (max-width: 63.9375em) {
  .Nav--lang {
    margin-top: 20px; } }

.Nav--lang .Nav-toggle {
  cursor: default;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  font-size: 14px; }
  .Nav--lang .Nav-toggle .Caret {
    height: 4px;
    width: 8px;
    position: relative;
    margin-left: 5px; }
    .Nav--lang .Nav-toggle .Caret:before, .Nav--lang .Nav-toggle .Caret:after {
      content: "";
      position: absolute; }
    .Nav--lang .Nav-toggle .Caret:before {
      top: 0;
      left: 0;
      border-top: 4px solid currentColor;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent; }
    .Nav--lang .Nav-toggle .Caret:after {
      left: 1px;
      top: 0;
      border-top: 3px solid #F7F7F7;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent; }

@media screen and (min-width: 64em) {
  .Nav--lang .Nav-list {
    transition-property: opacity, visibility;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: absolute;
    top: 100%;
    right: 0;
    color: #000000;
    background-color: #ffffff;
    flex-direction: column;
    margin-top: 10px; } }

@media screen and (max-width: 63.9375em) {
  .Nav--lang .Nav-list {
    justify-content: center;
    text-transform: uppercase; } }

.Nav--lang .Nav-link {
  color: rgba(0, 27, 53, 0.5);
  text-transform: capitalize; }
  @media screen and (min-width: 64em) {
    .Nav--lang .Nav-link {
      padding: 10px 20px 10px 10px;
      cursor: pointer;
      transition-property: border-left;
      transition-duration: 200ms;
      transition-timing-function: linear;
      border-left: 4px solid transparent;
      font-weight: 400; } }
  @media screen and (max-width: 63.9375em) {
    .Nav--lang .Nav-link {
      padding: 5px;
      text-transform: uppercase; }
      .Nav--lang .Nav-link::before {
        content: attr(lang); } }
  @media screen and (max-width: 63.9375em) and (max-width: 63.9375em) {
    .Nav--lang .Nav-link {
      font-weight: 400; } }
  @media screen and (min-width: 64em) {
    .Nav--lang .Nav-link.is-active {
      border-left: 4px solid #0047A0;
      color: #0047A0;
      transition-property: border-left;
      transition-duration: 200ms;
      transition-timing-function: linear; } }
  @media screen and (min-width: 64em) {
    .Nav--lang .Nav-link:not(.is-active):hover {
      border-left: 4px solid #0047A0;
      color: #0047A0; } }

.Nav--footer .Nav-list {
  margin-left: -10px; }

.Nav--footer .Nav-item {
  padding-left: 10px; }

/* Social Media */
.Nav--socialMedia {
  padding-top: 15px; }
  .Nav--socialMedia .Nav-item {
    margin-right: 20px;
    height: 16px;
    width: 16px; }
  .Nav--socialMedia .Nav-link {
    height: 16px;
    width: 16px; }
    .Nav--socialMedia .Nav-link svg {
      vertical-align: top; }
    .Nav--socialMedia .Nav-link svg path {
      transition-property: fill;
      transition-duration: 200ms;
      transition-timing-function: linear;
      fill: #ffffff; }
    .Nav--socialMedia .Nav-link:active svg path, .Nav--socialMedia .Nav-link:focus svg path, .Nav--socialMedia .Nav-link:hover svg path {
      transition-property: fill;
      transition-duration: 200ms;
      transition-timing-function: linear;
      fill: #F50057; }

/* Pagination */
.Nav--pagination {
  padding-top: 15px;
  margin-bottom: 120px; }
  .Nav--pagination .Nav-link {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #001B35;
    transition-property: background-color, color;
    transition-duration: 200ms;
    transition-timing-function: linear;
    margin-left: 5px;
    margin-right: 5px; }
    .Nav--pagination .Nav-link.is-active {
      background-color: rgba(0, 27, 53, 0.2); }
    .Nav--pagination .Nav-link:active, .Nav--pagination .Nav-link:focus, .Nav--pagination .Nav-link:hover {
      color: #ffffff;
      background-color: #001B35; }

/* Logo */
.Logo {
  flex-shrink: 0;
  margin-right: auto; }
  .Logo svg {
    display: block;
    width: 215px; }
    @media screen and (max-width: 47.9375em) {
      .Logo svg {
        width: 215px; } }

/* Fields */
/* Fields: field */
.Form-field--input,
.Form-field--textarea, .Form-field--select, .Form-field--select[multiple] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #001B35;
  color: #001B35;
  vertical-align: middle;
  width: 100%; }
  @media screen and (min-width: 37.5em) {
    .Form-field--input,
    .Form-field--textarea, .Form-field--select, .Form-field--select[multiple] {
      max-width: 354px; } }
  .Form-item.is-error .Form-field--input,
  .Form-item.is-error .Form-field--textarea, .Form-item.is-error .Form-field--select {
    border-color: #f44336; }
  .Form-item.is-success .Form-field--input,
  .Form-item.is-success .Form-field--textarea, .Form-item.is-success .Form-field--select {
    border-color: #4caf50; }

.Form-field--input,
.Form-field--textarea, .Form-field--select {
  box-shadow: none; }
  .Form-field--input:focus,
  .Form-field--textarea:focus, .Form-field--select:focus {
    border-color: #001B35;
    box-shadow: none , 0 0 2px 1px #64b5f6;
    outline: 0;
    z-index: 1; }
    .Form-item.is-error .Form-field--input:focus,
    .Form-item.is-error .Form-field--textarea:focus, .Form-item.is-error .Form-field--select:focus {
      box-shadow: none , 0 0 2px 1px rgba(244, 67, 54, 0.6); }
    .Form-item.is-success .Form-field--input:focus,
    .Form-item.is-success .Form-field--textarea:focus, .Form-item.is-success .Form-field--select:focus {
      box-shadow: none , 0 0 2px 1px rgba(76, 175, 80, 0.6); }

.Form-field {
  font-family: inherit;
  position: relative; }

.Form-indicator {
  user-select: none; }

.Form-field--xs {
  max-width: 60px !important; }

.Form-field--sm {
  max-width: 100px !important; }

.Form-field--md {
  max-width: 200px !important; }

.Form-field--lg {
  max-width: 300px !important; }

.Form-field--xl {
  max-width: 400px !important; }

.Form-field--full {
  max-width: none !important; }

/* Fields: check */
.Form-field--radio,
.Form-field--checkbox {
  cursor: pointer;
  display: flex;
  user-select: none; }
  .Form-field--radio:not(:first-of-type),
  .Form-field--checkbox:not(:first-of-type) {
    margin-top: 2.5px; }
  .Form-field--radio input[type="radio"],
  .Form-field--radio input[type="checkbox"],
  .Form-field--checkbox input[type="radio"],
  .Form-field--checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--radio input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
      background-color: #001B35;
      border-color: #001B35; }
    .Form-field--radio input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:focus ~ .Form-indicator {
      border-color: #001B35;
      box-shadow: 0 0 2px 1px #64b5f6; }
  .Form-field--radio .Form-indicator,
  .Form-field--checkbox .Form-indicator {
    background-color: #ffffff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.5625em 0.5625em;
    border: 1px solid #001B35;
    flex-shrink: 0;
    height: 1em;
    margin-right: 10px;
    width: 1em;
    position: relative;
    top: 0.1875em; }

.Form-field--radio > .Form-indicator {
  border-radius: 100%; }

.Form-field--radio input[type="radio"]:checked ~ .Form-indicator::after {
  background-color: #ffffff;
  border-radius: 100%;
  content: '';
  height: 0.3em;
  width: 0.3em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.Form-field--checkbox > .Form-indicator {
  border-radius: 0; }

.Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 6.3'%3E%3Ctitle%3ECheck%3C/title%3E%3Cpath d='M6.4,0,5.7.7,2.9,3.5l-.8-.8L1.4,2,0,3.4l.7.7L2.2,5.6l.7.7.7-.7L7.1,2.1l.7-.7Z' fill='%23fff'/%3E%3C/svg%3E"); }

/* Fields: file */
.Form-field--file {
  cursor: pointer;
  display: block; }
  .Form-field--file input[type='file'] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--file input[type='file']:focus ~ .Form-indicator {
      border-color: #001B35;
      box-shadow: none , 0 0 2px 1px #64b5f6; }
      .Form-item.is-error .Form-field--file input[type='file']:focus ~ .Form-indicator {
        box-shadow: none , 0 0 2px 1px rgba(244, 67, 54, 0.6); }
      .Form-item.is-success .Form-field--file input[type='file']:focus ~ .Form-indicator {
        box-shadow: none , 0 0 2px 1px rgba(76, 175, 80, 0.6); }
  .Form-field--file .Form-indicator {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid #001B35;
    box-shadow: none;
    color: #001B35;
    display: block;
    line-height: normal;
    overflow: hidden;
    padding: 10px 20px 11px;
    position: relative;
    white-space: nowrap;
    width: 100%; }
    .Form-field--file .Form-indicator::before {
      content: attr(data-file-text);
      display: block;
      color: rgba(0, 27, 53, 0.5);
      padding-right: 50px; }
    .Form-field--file .Form-indicator::after {
      background-color: #001B35;
      color: #ffffff;
      border-radius: 50px;
      content: "";
      padding: 1.25em 0.41667em 0.9375em 0.41667em;
      text-align: center;
      width: 50px;
      background-image: url("../img/icons/arrow.svg");
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0; }
  @media screen and (min-width: 37.5em) {
    .Form-field--file {
      max-width: 354px; } }

/* Fields: input */
.Form-field--input {
  padding: 1.25em 0.41667em 0.9375em 0.41667em; }

.Form-field--textarea {
  padding: 1.125em 0.41667em 0.8125em 0.41667em;
  max-width: 806px; }

/* Fields: select */
select {
  cursor: pointer;
  width: 100% !important; }
  @media screen and (min-width: 37.5em) {
    select {
      max-width: 354px; } }

.Form-field--select {
  background-image: url("../img/icons/arrow-select.svg");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 10px;
  box-shadow: none;
  line-height: normal;
  overflow: hidden;
  padding: 1.25em 2.5em 0.9375em 0.41667em; }

.Form-field--select[multiple] {
  height: auto; }
  .Form-field--select[multiple] option {
    padding: 0.625em 0.41667em 0.46875em 0.41667em; }
    .Form-field--select[multiple] option + option {
      border-top: 1px solid #001B35; }

/* Slider */
/* Slider: core */
.Slider {
  position: relative;
  z-index: 1;
  /* Fix of Webkit flickering */
  overflow: hidden; }
  .Slider--no-flexbox .Slider-item {
    float: left; }
  .Slider--vertical > .Slider-items {
    flex-direction: column; }
  .Slider-items {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    transition-property: transform; }
  .Slider--android .Slider-item, .Slider-items {
    transform: translate3d(0px, 0, 0); }
  .Slider--multirow > .Slider-items {
    flex-wrap: wrap; }
  .Slider--free-mode > .Slider-items {
    transition-timing-function: ease-out;
    margin: 0 auto; }
  .Slider-item {
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    transition-property: transform; }
    .Slider-item.is-blank {
      visibility: hidden; }
  .Slider--autoheight,
  .Slider--autoheight .Slider-item {
    height: auto; }
  .Slider--autoheight .Slider-items {
    align-items: flex-start;
    transition-property: transform, height; }
  .Slider--3d {
    perspective: 1200px; }
    .Slider--3d .Slider-items,
    .Slider--3d .Slider-item,
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom,
    .Slider--3d .swiper-cube-shadow {
      transform-style: preserve-3d; }
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10; }
    .Slider--3d .Slider-item-shadow-left {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-right {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-top {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-bottom {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .Slider--wp8-horizontal,
  .Slider--wp8-horizontal > .Slider-items {
    touch-action: pan-y; }
  .Slider--wp8-vertical,
  .Slider--wp8-vertical > .Slider-items {
    touch-action: pan-x; }
  .Slider-navigation {
    position: relative;
    height: 37px;
    width: 100%; }

/* Slider: a11y */
.Slider .Slider-notification {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
  pointer-events: none;
  opacity: 0; }

/* Slider: fade */
.Slider--fade.Slider--free-mode .Slider-item {
  transition-timing-function: ease-out; }

.Slider--fade .Slider-item {
  pointer-events: none;
  transition-property: opacity; }
  .Slider--fade .Slider-item .Slider-item {
    pointer-events: none; }

.Slider--fade .is-active,
.Slider--fade .is-active .is-active {
  pointer-events: auto; }

/* Slider: coverflow */
.Slider--coverflow .Slider-items {
  -ms-perspective: 1200px;
  /* Windows 8 IE 10 fix */ }

/* Slider: cube */
.Slider--cube {
  overflow: visible; }
  .Slider--cube .Slider-item {
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    pointer-events: none;
    transform-origin: 0 0;
    visibility: hidden;
    z-index: 1; }
    .Slider--cube .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--cube.Slider--rtl .Slider-item {
    transform-origin: 100% 0; }
  .Slider--cube .is-active,
  .Slider--cube .is-active .is-active {
    pointer-events: auto; }
  .Slider--cube .is-active,
  .Slider--cube .is-next,
  .Slider--cube .is-prev,
  .Slider--cube .is-next + .Slider-item {
    pointer-events: auto;
    visibility: visible; }
  .Slider--cube .swiper-slide-shadow-top,
  .Slider--cube .swiper-slide-shadow-bottom,
  .Slider--cube .swiper-slide-shadow-left,
  .Slider--cube .swiper-slide-shadow-right {
    backface-visibility: hidden;
    z-index: 0; }
  .Slider--cube .swiper-cube-shadow {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: #000000;
    filter: blur(50px);
    opacity: 0.6; }

/* Slider: flip */
.Slider--flip {
  overflow: visible; }
  .Slider--flip .Slider-item {
    backface-visibility: hidden;
    pointer-events: none;
    z-index: 1; }
    .Slider--flip .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--flip .is-active,
  .Slider--flip .is-active .is-active {
    pointer-events: auto; }
  .Slider--flip .Slider-item--shadow-top,
  .Slider--flip .Slider-item--shadow-bottom,
  .Slider--flip .Slider-item--shadow-left,
  .Slider--flip .Slider-item--shadow-right {
    backface-visibility: hidden;
    z-index: 0; }

/* Slider: lazy */
.Slider-preloader {
  height: 42px;
  width: 42px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
  margin-top: -21px;
  margin-left: -21px; }
  .Slider-preloader:after {
    height: 100%;
    width: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    display: block; }

.Slider-preloader--white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

/* Slider: navigation */
.Slider-button {
  height: 37px;
  width: 37px;
  position: absolute;
  top: 50%;
  z-index: 10;
  margin-top: -22px;
  cursor: pointer; }
  .Slider-button.is-disabled {
    cursor: auto;
    opacity: 0.35;
    pointer-events: none; }
  .Slider-button.swiper-button-lock {
    display: none; }
  .Slider-button.is-locked {
    display: none; }
  .Slider-button:hover svg path, .Slider-button:active svg path {
    fill: #ffffff;
    transition-property: fill;
    transition-duration: 200ms;
    transition-timing-function: linear; }
  .Slider-button:hover svg circle, .Slider-button:active svg circle {
    fill: #001B35;
    stroke: transparent;
    transition-property: stroke, fill;
    transition-duration: 200ms;
    transition-timing-function: linear; }
  .Slider-button:hover svg g, .Slider-button:active svg g {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: linear; }
  .Slider-button:focus {
    outline: none; }

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
  right: 45px;
  left: auto; }
  @media screen and (max-width: 48em) {
    .Slider-button--prev,
    .Slider--rtl .Slider-button--next {
      right: auto;
      left: 0px; } }

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
  right: 0px;
  transform: rotate(180deg);
  left: auto; }
  @media screen and (max-width: 48em) {
    .Slider-button--next,
    .Slider--rtl .Slider-button--prev {
      right: auto;
      left: 45px; } }

/* Slider: pagination */
.Slider-pagination {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  position: absolute;
  z-index: 10;
  text-align: center;
  transform: translate3d(0, 0, 0); }
  .Slider-pagination.is-hidden {
    opacity: 0; }
  .Slider-pagination.is-locked {
    display: none; }

.Slider-pagination--fraction,
.Slider-pagination--custom,
.Slider--horizontal > .Slider-pagination--bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

.Slider-pagination--bullets-dynamic {
  overflow: hidden;
  font-size: 0; }
  .Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transform: scale(0.33);
    position: relative; }
  .Slider-pagination--bullets-dynamic .is-active {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-main {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-prev {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-prev-prev {
    transform: scale(0.33); }
  .Slider-pagination--bullets-dynamic .is-active-next {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-next-next {
    transform: scale(0.33); }

.Slider-pagination-item {
  height: 8px;
  width: 8px;
  background: #000000;
  border-radius: 100%;
  display: inline-block;
  opacity: 0.2; }
  button.Slider-pagination-item {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none; }
  .is-clickable .Slider-pagination-item {
    cursor: pointer; }
  .Slider-pagination-item.is-active {
    background-color: currentColor;
    opacity: 1; }

.Slider--vertical > .Slider-pagination--bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0); }
  .Slider--vertical > .Slider-pagination--bullets .Slider-pagination-item {
    display: block;
    margin-right: 6px;
    margin-left: 6px; }
  .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px; }
    .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
      display: inline-block;
      transition-property: transform, top;
      transition-duration: 200ms; }

.Slider--horizontal > .Slider-pagination--bullets .Slider-pagination-item {
  margin: 0 4px; }

.Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; }
  .Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transition-property: transform, left;
    transition-duration: 200ms; }

.Slider--horizontal.Slider--rtl > .Slider-pagination--bullets-dynamic .Slider-pagination-item {
  transition-property: transform, right;
  transition-duration: 200ms; }

.Slider-pagination--progressbar {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute; }
  .Slider-pagination--progressbar .Slider-pagination-fill {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: currentColor;
    transform: scale(0);
    transform-origin: left top; }
  .Slider--rtl .Slider-pagination--progressbar .Slider-pagination-fill {
    transform-origin: right top; }
  .Slider--horizontal > .Slider-pagination--progressbar,
  .Slider--vertical > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 4px;
    width: 100%;
    left: 0;
    top: 0; }
  .Slider--vertical > .Slider-pagination--progressbar,
  .Slider--horizontal > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 100%;
    width: 4px;
    left: 0;
    top: 0; }

/* Slider: scrollbar */
.Slider-scrollbar {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none; }
  .Slider--horizontal > .Slider-scrollbar {
    height: 5px;
    width: 98%;
    position: absolute;
    bottom: 3px;
    left: 1%;
    z-index: 50; }
  .Slider--vertical > .Slider-scrollbar {
    height: 98%;
    width: 5px;
    position: absolute;
    top: 1%;
    right: 3px;
    z-index: 50; }
  .Slider-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px; }
  .Slider-scrollbar-cursor-drag {
    cursor: move; }
  .Slider-scrollbar .is-locked {
    display: none; }

/* Slider: thumbs */
/* Slider: zoom */
.Slider--zoom {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .Slider--zoom > img,
  .Slider--zoom > svg,
  .Slider--zoom > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; }
  .Slider--zoom .is-zoomed {
    cursor: move; }

/* General */
.LazyLoad,
.LazyLoading {
  opacity: 0; }

.LazyLoaded {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  opacity: 1; }

.js-slider--partners-home .Slider-items .Slider-item img, .js-slider--partners-home .Slider-items .Slider-item svg {
  filter: grayscale(100%);
  transition-property: filter;
  transition-duration: 200ms;
  transition-timing-function: linear; }

.js-slider--partners-home .Slider-items .Slider-item:hover img, .js-slider--partners-home .Slider-items .Slider-item:hover svg, .js-slider--partners-home .Slider-items .Slider-item:active img, .js-slider--partners-home .Slider-items .Slider-item:active svg {
  filter: grayscale(0%);
  transition-property: filter;
  transition-duration: 200ms;
  transition-timing-function: linear; }

.Pagination {
  display: flex;
  justify-content: center; }
  .Pagination .paginationjs .paginationjs-pages .Pagination-page {
    border: none;
    height: 45px;
    width: 45px;
    border-radius: 50%; }
    .Pagination .paginationjs .paginationjs-pages .Pagination-page.active a {
      background-color: rgba(0, 27, 53, 0.2); }
    .Pagination .paginationjs .paginationjs-pages .Pagination-page a {
      height: 45px;
      width: 45px;
      border-radius: 50%;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #001B35;
      font-weight: 600;
      font-size: 16px; }
    .Pagination .paginationjs .paginationjs-pages .Pagination-page::before {
      content: none; }

.is-sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 40px; }

.Filter {
  padding: 40px 45px 25px;
  border: 1px solid rgba(0, 27, 53, 0.1);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 20px; }
  @media screen and (max-width: 63.9375em) {
    .Filter {
      padding: 21px 25px;
      margin-bottom: 35px; } }

.Search {
  background-color: #ffffff;
  margin-bottom: 35px;
  border: 1px solid rgba(0, 27, 53, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 45px 25px 45px; }
  @media screen and (max-width: 63.9375em) {
    .Search {
      padding: 10px 25px; } }
  @media screen and (max-width: 47.9375em) {
    .Search {
      padding: 10px 25px;
      margin-bottom: 15px; } }
  .Search .Input {
    border: none;
    font-size: 20px;
    width: 100%;
    height: 20px;
    height: 41px;
    line-height: 41px; }
    @media screen and (max-width: 63.9375em) {
      .Search .Input {
        font-size: 16px; } }
    .Search .Input:focus {
      outline: none; }
    .Search .Input::placeholder {
      font-weight: 700;
      color: rgba(0, 27, 53, 0.25); }
  .Search .Remove {
    height: 18px;
    width: 18px;
    background-image: url("../img/icons/remove.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer; }
  .Search .Magnifier {
    height: 24px;
    width: 24px;
    background-image: url("../img/icons/magnifier.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer; }

.PageHeader {
  border-bottom: 1px solid rgba(0, 27, 53, 0.5);
  margin-bottom: 35px; }
  @media screen and (max-width: 47.9375em) {
    .PageHeader.has-filter {
      margin-bottom: 140px; } }
  .PageHeader .Container {
    display: flex;
    justify-content: space-between; }
    .PageHeader .Container .Meta {
      font-size: 18px;
      color: rgba(0, 27, 53, 0.5);
      margin-bottom: 10px; }
  .PageHeader--filter {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    padding-bottom: 38px;
    width: 100%; }
    @media screen and (max-width: 47.9375em) {
      .PageHeader--filter {
        max-width: 260px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: absolute;
        z-index: 4;
        bottom: -160px;
        padding-bottom: 20px; }
        .PageHeader--filter p {
          text-align: left !important; } }
    .PageHeader--filter p {
      color: #001B35;
      font-weight: 500;
      font-size: 17px;
      width: 100%;
      margin-bottom: 12px;
      text-align: right;
      margin-right: 25px; }
  .PageHeader.has-back .Back {
    position: absolute;
    right: 40px;
    top: 0; }
    @media screen and (max-width: 47.9375em) {
      .PageHeader.has-back .Back {
        right: 20px; } }
  .PageHeader .Container {
    padding-top: 55px;
    padding-bottom: 0px;
    position: relative; }
    .PageHeader .Container h1 {
      display: inline-block;
      border-bottom: 6px solid #001B35;
      padding-bottom: 40px;
      margin-bottom: 0px; }

.Partner.Media-object {
  display: flex;
  justify-content: center; }
  .Partner.Media-object img {
    width: auto;
    max-height: 80px;
    height: auto;
    max-width: 120px; }

.Partners--detail .Logo {
  background-color: #ffffff;
  padding: 35px 20px;
  display: flex;
  justify-content: center;
  border: 1px solid rgba(0, 27, 53, 0.1); }
  @media screen and (max-width: 47.9375em) {
    .Partners--detail .Logo {
      padding: 35px 50px; }
      .Partners--detail .Logo img {
        max-width: 200px; } }

.Sponsors .Sponsor {
  padding: 0px 25px 30px; }
  @media screen and (max-width: 63.9375em) {
    .Sponsors .Sponsor {
      padding: 0px 0px 30px; } }
  .Sponsors .Sponsor h6 {
    color: #001B35; }

@media screen and (max-width: 63.9375em) {
  .Sidebar {
    display: none; } }

.Sidebar hr {
  margin-top: 15px; }

.Sidebar--jobs h5 {
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 20px; }
  .Sidebar--jobs h5:first-of-type {
    margin-top: 0; }

.Sidebar--jobs .Button--bare {
  padding-top: 5px;
  padding-left: 0;
  justify-content: flex-start;
  color: rgba(0, 27, 53, 0.5);
  font-weight: 500; }
  .Sidebar--jobs .Button--bare svg {
    opacity: 1;
    height: 20px; }
  .Sidebar--jobs .Button--bare:hover {
    color: #001B35; }

.Sidebar--blog .Meta {
  margin-top: 20px;
  font-size: 14px;
  color: rgba(0, 27, 53, 0.5);
  margin-bottom: 5px; }
  .Sidebar--blog .Meta:first-of-type {
    margin-top: 0; }

.Sidebar--blog h5 {
  font-weight: 600;
  margin-bottom: 5px; }

.Sidebar--blog .Button--bare {
  padding-top: 5px;
  padding-left: 0;
  justify-content: flex-start;
  color: rgba(0, 27, 53, 0.5);
  font-weight: 500; }
  .Sidebar--blog .Button--bare svg {
    opacity: 1;
    height: 20px; }
  .Sidebar--blog .Button--bare:hover {
    color: #001B35; }

.Sidebar a {
  color: #001B35;
  font-weight: 400;
  padding: 15px 3px;
  border-bottom: 1px solid rgba(0, 27, 53, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .Sidebar a svg {
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: linear;
    opacity: 0;
    height: 13px;
    margin-bottom: 4px; }
  .Sidebar a:hover svg {
    opacity: 1; }
  .Sidebar a:first-of-type {
    border-bottom: none;
    padding: 0px 0px;
    font-weight: 700;
    color: #0047A0;
    font-size: 20px; }
    .Sidebar a:first-of-type.router-link-exact-active {
      font-weight: 700; }
  .Sidebar a:last-of-type {
    border-bottom: 0; }
  .Sidebar a.router-link-exact-active {
    font-weight: 600; }
    .Sidebar a.router-link-exact-active svg {
      opacity: 1; }
  .Sidebar a.is-active {
    font-weight: 700; }

.Dropdown {
  display: none;
  position: relative;
  z-index: 2; }
  .Dropdown--blog {
    display: block;
    max-width: 220px;
    width: 100%; }
    .Dropdown--blog .Dropdown-label {
      border: 1px solid #001B35;
      padding: 11px 30px 10px;
      border-radius: 25px;
      font-size: 17px;
      font-weight: 500;
      color: rgba(0, 27, 53, 0.5); }
      .Dropdown--blog .Dropdown-label .Caret {
        height: 5px;
        width: 10px;
        position: relative;
        transition-property: transform;
        transition-duration: 200ms;
        transition-timing-function: linear; }
        .Dropdown--blog .Dropdown-label .Caret:before, .Dropdown--blog .Dropdown-label .Caret:after {
          content: "";
          position: absolute; }
        .Dropdown--blog .Dropdown-label .Caret:before {
          top: 0;
          left: 0;
          border-top: 5px solid rgba(0, 27, 53, 0.5);
          border-left: 5px solid transparent;
          border-right: 5px solid transparent; }
        .Dropdown--blog .Dropdown-label .Caret:after {
          left: 1px;
          top: 0;
          border-top: 4px solid #ffffff;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent; }
    .Dropdown--blog.is-active .Dropdown-items {
      width: calc(100%) !important;
      left: 0 !important; }
  @media screen and (max-width: 63.9375em) {
    .Dropdown {
      display: block; } }
  .Dropdown-label {
    font-weight: 700;
    color: rgba(0, 27, 53, 0.25);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .Dropdown-label .Caret {
      height: 6px;
      width: 12px;
      position: relative;
      transition-property: transform;
      transition-duration: 200ms;
      transition-timing-function: linear; }
      .Dropdown-label .Caret:before, .Dropdown-label .Caret:after {
        content: "";
        position: absolute; }
      .Dropdown-label .Caret:before {
        top: 0;
        left: 0;
        border-top: 6px solid currentColor;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent; }
      .Dropdown-label .Caret:after {
        left: 1px;
        top: 0;
        border-top: 5px solid #ffffff;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent; }
  .Dropdown.is-active .Dropdown-label .Caret {
    transform: rotate(-180deg); }
  .Dropdown.is-active .Dropdown-items {
    position: absolute;
    width: calc(100% + 52px);
    background: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
    left: -26px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding-top: 10px;
    padding-bottom: 20px;
    border: 1px solid rgba(0, 27, 53, 0.1);
    top: 45px; }
    .Dropdown.is-active .Dropdown-items .router-link-exact-active {
      color: #0047A0;
      font-weight: 700; }
    .Dropdown.is-active .Dropdown-items::after {
      content: "";
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: linear;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      box-shadow: 0px 5px 30px rgba(0, 27, 53, 0.1);
      z-index: -1; }
    .Dropdown.is-active .Dropdown-items .Meta {
      font-size: 12px;
      color: rgba(0, 27, 53, 0.5);
      margin-bottom: 10px; }
    .Dropdown.is-active .Dropdown-items .u-linkClean:last-of-type .Meta {
      margin-bottom: 0; }
  .Dropdown-items {
    display: none; }
    .Dropdown-items a {
      padding-top: 10px;
      color: #001B35;
      font-weight: 400; }

.Blue {
  color: #0047A0; }

.White {
  color: #ffffff; }

.Intro {
  margin-bottom: 25px;
  font-size: 20px; }
  .Intro p {
    line-height: 30px; }

.TextWithImage .Grid-cell {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.Party {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #001B35; }
  .Party h5 {
    margin-top: 30px;
    margin-bottom: 7px; }
  .Party p {
    font-weight: 400;
    margin-bottom: 40px; }
    @media screen and (max-width: 47.9375em) {
      .Party p {
        padding: 0px 40px; } }
  .Party:active, .Party:focus, .Party:hover, .Party.is-hovered {
    color: #001B35; }
    .Party:active .Circle, .Party:focus .Circle, .Party:hover .Circle, .Party.is-hovered .Circle {
      transition-property: background-color;
      transition-duration: 200ms;
      transition-timing-function: linear;
      background-color: #0047A0; }
      .Party:active .Circle .Icon-party, .Party:focus .Circle .Icon-party, .Party:hover .Circle .Icon-party, .Party.is-hovered .Circle .Icon-party {
        display: none; }
        .Party:active .Circle .Icon-party-hover, .Party:focus .Circle .Icon-party-hover, .Party:hover .Circle .Icon-party-hover, .Party.is-hovered .Circle .Icon-party-hover {
          display: block; }
  .Party .Circle {
    height: 70px;
    width: 70px;
    transition-property: background-color;
    transition-duration: 200ms;
    transition-timing-function: linear;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    position: relative; }
    .Party .Circle::after {
      content: "";
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: linear;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      box-shadow: 0px 5px 30px rgba(0, 27, 53, 0.1);
      border-radius: 50%; }
    .Party .Circle .Icon-party {
      display: block; }
      .Party .Circle .Icon-party-hover {
        display: none; }

.Form-wrapper {
  max-width: 886px; }

.ContactPage .Background--white {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  @media screen and (max-width: 63.9375em) {
    .ContactPage .Background--white {
      padding-top: 40px;
      padding-bottom: 40px; } }

.ContactPage .Background .Form-wrapper {
  padding: 0px 40px; }

@media screen and (max-width: 63.9375em) {
  .ContactPage .FlexEmbed--map:before {
    padding-bottom: 50%; } }

.ContactPage .GoogleMaps {
  position: relative;
  margin-top: 35px;
  margin-bottom: 120px; }
  @media screen and (max-width: 63.9375em) {
    .ContactPage .GoogleMaps {
      margin-top: 0px;
      margin-bottom: 0; } }

.Contact-info {
  padding: 50px;
  background-color: #0047A0;
  color: #ffffff;
  position: absolute;
  z-index: 1;
  bottom: 0;
  transform: translateY(50%);
  left: calc(100% * 1 / 12); }
  @media screen and (max-width: 63.9375em) {
    .Contact-info {
      position: static;
      transform: none; } }
  .Contact-info h3 {
    margin-bottom: 10px; }
  .Contact-info p {
    margin: 0; }
    .Contact-info p a {
      color: #ffffff;
      font-weight: 400; }

.Form-item.is-active .Select-selected {
  color: #001B35;
  padding-left: 6.66667px; }

.CustomSelect {
  position: relative;
  border-bottom: 1px solid rgba(0, 27, 53, 0.25);
  max-width: 354px; }
  @media screen and (max-width: 47.9375em) {
    .CustomSelect {
      max-width: 800px; } }
  .CustomSelect.is-active::after {
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: linear;
    transform: translateY(-50%) rotate(-180deg); }
  .CustomSelect::after {
    height: 7px;
    width: 10px;
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: linear;
    content: "";
    position: absolute;
    background-image: url("../img/icons/arrow-select.svg");
    right: 0;
    background-repeat: no-repeat;
    background-size: 10px;
    top: 50%;
    transform: translateY(-50%); }
  .CustomSelect select {
    display: none; }
  .CustomSelect .Select-selected {
    color: #001B35;
    padding: 18px 6.66667px 13px 0px;
    cursor: pointer;
    user-select: none; }
    .CustomSelect .Select-selected:after {
      position: absolute;
      content: "";
      top: 14px;
      right: 10px;
      width: 8px;
      height: 8px;
      transform: rotate(135deg);
      opacity: 0.5;
      border: solid 2px #F7F7F7;
      border-left: 0;
      border-bottom: 0;
      display: block;
      margin-bottom: 2px;
      transition-property: border-color;
      transition-duration: 200ms;
      transition-timing-function: linear; }
    .CustomSelect .Select-selected .Select-arrow-active:after {
      transform: rotate(-45deg);
      top: 17px; }
  .CustomSelect .Select-items {
    padding: 10px 20px;
    position: absolute;
    background-color: #ffffff;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99; }
    .CustomSelect .Select-items div {
      color: #001B35;
      padding: 10px 0px 0px 0px;
      border: 1px solid transparent;
      border-color: transparent transparent #ffffff transparent;
      cursor: pointer;
      user-select: none; }
      .CustomSelect .Select-items div.is-active {
        color: #0047A0 !important;
        font-weight: 700; }
      .CustomSelect .Select-items div:last-of-type {
        border: none;
        padding-bottom: 10px; }
    .CustomSelect .Select-items::after {
      content: "";
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: linear;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      box-shadow: 0px 5px 30px rgba(0, 27, 53, 0.1);
      z-index: -1; }
  .CustomSelect .Select-hide {
    display: none; }

.Article {
  margin-bottom: 55px;
  position: relative; }
  .Article.u-first .Thumbnail {
    position: relative;
    min-height: 543px; }
    .Article.u-first .Thumbnail img {
      height: 100%;
      object-fit: contain;
      width: 100%;
      position: absolute;
      background-color: white;
      padding: 40px; }
    .Article.u-first .Thumbnail::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-image: linear-gradient(to right, #001b35, rgba(255, 255, 255, 0));
      background-color: transparent;
      opacity: 1;
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: linear; }
  .Article.u-first:active .Thumbnail::after, .Article.u-first:hover .Thumbnail::after {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: linear; }
  .Article.u-first:active .Button--bare .Icon-arrow-bare circle, .Article.u-first:hover .Button--bare .Icon-arrow-bare circle {
    fill: white; }
  .Article.u-first:active .Button--bare .Icon-arrow-bare path, .Article.u-first:hover .Button--bare .Icon-arrow-bare path {
    stroke: #001b35; }
  .Article .Overlay {
    position: absolute;
    top: 35px;
    right: 60px;
    bottom: 60px;
    left: 160px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media screen and (max-width: 47.9375em) {
      .Article .Overlay {
        position: absolute;
        top: 15px;
        right: 20px;
        bottom: 40px;
        left: 120px;
        z-index: 3; } }
    .Article .Overlay * {
      color: #ffffff; }
    .Article .Overlay .Meta {
      color: #ffffff;
      font-size: 18px;
      max-width: 480px; }
    .Article .Overlay h3 {
      font-size: 37px;
      max-width: 480px; }
    .Article .Overlay p {
      font-size: 18px;
      max-width: 480px; }
    .Article .Overlay .Button--bare {
      font-size: 18px;
      display: flex;
      align-items: center; }
      .Article .Overlay .Button--bare .Icon-arrow-bare circle {
        fill: rgba(255, 255, 255, 0.5); }
  .Article .Meta {
    color: rgba(0, 27, 53, 0.5);
    font-size: 14px;
    margin-top: 25px;
    margin-bottom: 10px; }
  .Article .Thumbnail {
    position: relative; }
    .Article .Thumbnail::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: #001B35;
      opacity: 0;
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: linear; }
  .Article:active .Thumbnail::after, .Article:hover .Thumbnail::after {
    opacity: 0.35;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: linear; }

.ArticlePage h2, .ArticlePage h3 {
  color: #0047A0; }

.ArticlePage h2 {
  margin-bottom: 30px; }

.ArticlePage h3 {
  margin-bottom: 20px; }

.ArticlePage h4 {
  color: #001B35;
  margin-bottom: 20px; }

.ArticlePage .Text {
  margin-bottom: 45px; }

.ArticlePage .Media-object {
  margin-bottom: 45px;
  display: inline-block; }
  .ArticlePage .Media-object--video .Media-object {
    margin-bottom: 0; }

.ArticlePage .Images {
  margin-bottom: 30px; }
  .ArticlePage .Images .Media-object {
    margin-bottom: 15px; }

.ArticlePage .Author {
  padding-top: 40px;
  margin-top: 25px;
  margin-bottom: 120px;
  border-top: 1px solid #0047A0; }
  @media screen and (max-width: 63.9375em) {
    .ArticlePage .Author {
      margin-bottom: 40px; } }
  .ArticlePage .Author .Avatar {
    position: relative;
    height: 76px;
    width: 76px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block; }
    .ArticlePage .Author .Avatar img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      object-fit: cover; }

.Quote {
  padding: 15px 160px 50px 60px;
  font-weight: 700;
  color: #0047A0;
  font-size: 30px;
  line-height: 40px;
  display: flex; }
  @media screen and (max-width: 47.9375em) {
    .Quote {
      padding: 15px 30px 50px 30px; } }
  @media screen and (max-width: 37.4375em) {
    .Quote {
      padding: 15px 0px 50px 0px; } }
  .Quote .Decoration {
    height: 183px;
    width: 183px;
    background-color: #ffffff;
    position: relative;
    display: flex;
    margin-right: 70px; }
    @media screen and (max-width: 47.9375em) {
      .Quote .Decoration {
        height: 120px;
        width: 120px;
        margin-right: 40px; } }
    @media screen and (max-width: 37.4375em) {
      .Quote .Decoration {
        height: 60px;
        width: 60px;
        margin-right: 40px; } }
    .Quote .Decoration .Icon {
      position: absolute;
      right: -20px; }
      @media screen and (max-width: 47.9375em) {
        .Quote .Decoration .Icon {
          height: 40px;
          width: 40px;
          right: -10px; } }
      @media screen and (max-width: 37.4375em) {
        .Quote .Decoration .Icon {
          height: 30px;
          width: 30px;
          right: -10px; } }
  .Quote span {
    width: calc(100% - 253px);
    display: flex;
    align-items: center; }
    @media screen and (max-width: 47.9375em) {
      .Quote span {
        width: calc(100% - 193px);
        right: -10px; } }
    @media screen and (max-width: 37.4375em) {
      .Quote span {
        width: calc(100% - 133px);
        right: -10px; } }

.Banner {
  background-color: #F7F7F7;
  z-index: 0;
  position: relative; }
  @media screen and (max-width: 79.9375em) {
    .Banner {
      padding-bottom: 120px; } }
  @media screen and (max-width: 63.9375em) {
    .Banner {
      padding-bottom: 40px; } }
  .Banner .Grid-cell:last-of-type {
    padding-bottom: 120px; }
    @media screen and (max-width: 63.9375em) {
      .Banner .Grid-cell:last-of-type {
        padding-bottom: 0px; } }
  .Banner--home {
    background-color: #F7F7F7;
    padding-bottom: 160px; }
    .Banner--home .Grid .Grid-cell {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .Banner--home .Grid .Grid-cell h1 {
        font-size: 60px; }
        @media screen and (max-width: 47.9375em) {
          .Banner--home .Grid .Grid-cell h1 {
            margin-bottom: 22px;
            font-size: 35px;
            line-height: 1.2; } }
      .Banner--home .Grid .Grid-cell .Text {
        margin-top: -10px;
        margin-bottom: 5px;
        display: inline-flex;
        padding-left: 100px;
        position: relative;
        font-size: 18px; }
        @media screen and (max-width: 47.9375em) {
          .Banner--home .Grid .Grid-cell .Text {
            padding-left: 0;
            font-size: 16px; } }
        .Banner--home .Grid .Grid-cell .Text .Decoration {
          width: 71px;
          height: 2px;
          background-color: #0047A0;
          position: absolute;
          left: 0;
          top: 5px; }
          @media screen and (max-width: 47.9375em) {
            .Banner--home .Grid .Grid-cell .Text .Decoration {
              display: none; } }
      .Banner--home .Grid .Grid-cell:last-of-type {
        display: flex;
        align-items: center; }
        @media screen and (max-width: 63.9375em) {
          .Banner--home .Grid .Grid-cell:last-of-type {
            margin-top: 100px;
            justify-content: center; } }
        @media screen and (max-width: 47.9375em) {
          .Banner--home .Grid .Grid-cell:last-of-type {
            margin-top: 40px;
            justify-content: center; } }
      .Banner--home .Grid .Grid-cell > svg, .Banner--home .Grid .Grid-cell img {
        width: 100%;
        max-width: 700px;
        height: auto; }
  .Banner h2 p {
    margin-bottom: 0px; }
  @media screen and (max-width: 79.9375em) {
    .Banner img {
      height: calc(100% + 120px);
      width: 100%;
      object-fit: cover; } }
  @media screen and (max-width: 63.9375em) {
    .Banner img {
      max-height: 500px;
      height: 100%; } }
  .Banner::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 120px;
    width: 100%;
    background-color: #ffffff;
    z-index: -1; }
    @media screen and (max-width: 63.9375em) {
      .Banner::after {
        content: none; } }

.Vacancies {
  margin-top: -15px;
  margin-bottom: 25px; }

.Step .Number {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 50%;
  background-color: #0047A0;
  height: 54px;
  width: 54px;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px; }

.Step p {
  font-size: 18px; }

.Value h6 {
  color: #001B35;
  margin-top: 25px; }

.Value p {
  text-align: center; }

.CallToAction {
  background-color: #F7F7F7;
  padding: 100px 0px;
  margin-top: 100px;
  position: relative; }
  .CallToAction::after {
    content: "";
    height: 200px;
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    top: -200px;
    z-index: -1; }
  .CallToAction--white {
    background-color: #ffffff; }
    .CallToAction--white::after {
      content: "";
      background-color: #F7F7F7; }
  @media screen and (max-width: 63.9375em) {
    .CallToAction {
      margin-top: 0px; }
      .CallToAction::after {
        display: none; } }
  .CallToAction .Grid .Grid-cell:last-of-type {
    margin-top: -200px;
    display: flex;
    justify-content: flex-end; }
    .CallToAction .Grid .Grid-cell:last-of-type svg, .CallToAction .Grid .Grid-cell:last-of-type img {
      width: 100%;
      max-width: 630px;
      height: auto; }
    @media screen and (max-width: 63.9375em) {
      .CallToAction .Grid .Grid-cell:last-of-type {
        margin-top: 100px;
        justify-content: center; } }
    @media screen and (max-width: 47.9375em) {
      .CallToAction .Grid .Grid-cell:last-of-type {
        margin-top: 20px;
        justify-content: center; } }

@media screen and (max-width: 47.9375em) {
  .Link {
    margin-bottom: 10px;
    margin-right: 10px; } }

.Link + .Link {
  margin-left: 10px; }
  @media screen and (max-width: 47.9375em) {
    .Link + .Link {
      margin-left: 0px; } }

.Medium {
  font-weight: 500; }

.Border-top {
  border-top: 1px solid rgba(0, 27, 53, 0.1); }

.Border-right {
  border-right: 1px solid rgba(0, 27, 53, 0.1); }

.Border-bottom {
  border-bottom: 1px solid rgba(0, 27, 53, 0.1); }

.Border-left {
  border-left: 1px solid rgba(0, 27, 53, 0.1); }

.KeypointConnect {
  position: absolute;
  top: 0;
  right: 40px;
  font-size: 14px; }
  @media screen and (max-width: 63.9375em) {
    .KeypointConnect {
      right: auto;
      top: auto;
      left: 50%;
      transform: translateX(-50%);
      bottom: 70px;
      width: 100%;
      text-align: center; } }

.Bold {
  font-weight: 700; }

.Newsletter {
  max-width: 290px;
  width: 100%; }
  .Newsletter p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px; }
  .Newsletter .Form {
    max-width: 290px;
    width: 100%;
    margin-top: 0; }
    .Newsletter .Form .Form-item .Form-field {
      border: 2px solid rgba(255, 255, 255, 0.5);
      padding: 0px 20px;
      font-size: 16px; }
      .Newsletter .Form .Form-item .Form-field.Form-field--input {
        background-image: url("../img/icons/send.svg");
        background-position: calc(100% - 14px) center;
        background-size: 16px;
        background-repeat: no-repeat;
        color: #ffffff;
        height: 40px;
        line-height: 40px; }
  .Newsletter .Button--bare {
    color: #ffffff;
    transition-property: color;
    transition-duration: 200ms;
    transition-timing-function: linear; }
    .Newsletter .Button--bare svg circle {
      fill: #ffffff; }
    .Newsletter .Button--bare svg path {
      stroke: #001B35; }
    .Newsletter .Button--bare:hover, .Newsletter .Button--bare:active, .Newsletter .Button--bare:focus {
      color: #F50057; }
      .Newsletter .Button--bare:hover svg circle, .Newsletter .Button--bare:active svg circle, .Newsletter .Button--bare:focus svg circle {
        fill: #F50057; }
      .Newsletter .Button--bare:hover svg path, .Newsletter .Button--bare:active svg path, .Newsletter .Button--bare:focus svg path {
        stroke: #001B35; }

.u-negativeMarginTop {
  margin-top: -30px; }

/* Blocks */
.Block {
  padding: 120px 0px;
  background-color: #F7F7F7; }
  @media screen and (max-width: 47.9375em) {
    .Block {
      padding: 60px 0px; } }
  .Block--white {
    background-color: #ffffff; }
  .Block--small {
    padding-bottom: 80px; }
  .Block--news {
    position: relative;
    overflow: hidden; }
    .Block--news::after {
      content: "";
      position: absolute;
      right: -40px;
      bottom: -40px;
      background-image: url("../img/element.svg");
      height: 236px;
      width: 263px;
      background-position: center;
      background-position: contain; }
  .Block--partners {
    padding: 0px 0px; }
    @media screen and (max-width: 47.9375em) {
      .Block--partners {
        padding: 0px 0px; } }
    .Block--partners.Block--white .Slider {
      background-color: #ffffff; }
    .Block--partners .has-background {
      background-image: url("../img/background.svg");
      background-position: center top;
      background-size: cover;
      color: #ffffff;
      padding-top: 100px;
      padding-bottom: 105px; }
      .Block--partners .has-background hr {
        border-color: #ffffff; }
    .Block--partners .Slider {
      background-color: #F7F7F7;
      height: 200px;
      margin-top: -100px; }
      .Block--partners .Slider-item {
        display: flex;
        justify-content: center;
        align-items: center; }
  .Block.u-imageFirst .Grid {
    flex-direction: row-reverse; }

/* Footer */
.Footer {
  background-color: #001B35;
  color: #ffffff; }
  .Footer a {
    color: #ffffff;
    font-weight: 400; }
    .Footer a:hover, .Footer a:active {
      color: #F50057; }
  .Footer .Logo svg {
    display: block;
    width: 235px; }
    @media screen and (max-width: 47.9375em) {
      .Footer .Logo svg {
        width: 150px; } }
  .Footer-top {
    padding-top: 100px;
    padding-bottom: 60px; }
  .Footer-bottom {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 35px; }
    .Footer-bottom .Nav--footer .Nav-list .Nav-item {
      color: rgba(255, 255, 255, 0.5); }
      .Footer-bottom .Nav--footer .Nav-list .Nav-item .Nav-link {
        color: rgba(255, 255, 255, 0.5); }
        .Footer-bottom .Nav--footer .Nav-list .Nav-item .Nav-link:hover, .Footer-bottom .Nav--footer .Nav-list .Nav-item .Nav-link:active {
          color: #F50057; }
    .Footer-bottom .Nav--copyright .Nav-list .Nav-item {
      color: rgba(255, 255, 255, 0.5);
      font-size: 13px;
      padding-left: 35px; }
      .Footer-bottom .Nav--copyright .Nav-list .Nav-item:first-of-type {
        padding-left: 0; }
      .Footer-bottom .Nav--copyright .Nav-list .Nav-item .Nav-link {
        color: rgba(255, 255, 255, 0.5); }
        .Footer-bottom .Nav--copyright .Nav-list .Nav-item .Nav-link:hover, .Footer-bottom .Nav--copyright .Nav-list .Nav-item .Nav-link:active {
          color: #F50057; }

/* DigitalPulse */
.DigitalPulse {
  color: rgba(255, 255, 255, 0.5) !important;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  margin-left: auto;
  height: 1.5em;
  font-size: 14px; }
  .DigitalPulse:hover, .DigitalPulse:active {
    color: #F50057 !important; }
  .DigitalPulse-icon, .DigitalPulse-body {
    display: block;
    fill: currentColor; }
  .DigitalPulse-label {
    margin-right: .3em;
    flex-shrink: 0;
    font-weight: 400; }
  .DigitalPulse-object {
    background-color: #001B35;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .DigitalPulse-icon {
    flex-shrink: 0;
    height: 1.85743em;
    transform: translateY(5%);
    width: 1.5em; }
  @media screen and (min-width: 64em) {
    .DigitalPulse-object {
      transition-property: transform, right;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      min-width: 5.56167em;
      position: relative;
      z-index: 1;
      position: absolute;
      top: 50%;
      right: 0;
      transform: scale(1.01) translateY(-50%); }
    .DigitalPulse:hover .DigitalPulse-object {
      color: #F50057;
      right: 100%; }
    .DigitalPulse-body {
      transition-property: width;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      margin-left: .3em;
      transform: translateY(12.5%);
      display: flex;
      justify-content: flex-end;
      height: 0.94828em;
      width: 5.56167em; } }

.Spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: spinner 1.0s infinite ease-in-out;
  animation: spinner 1.0s infinite ease-in-out; }

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes spinner {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

.Tile {
  padding: 35px 30px;
  border: 1px solid rgba(0, 27, 53, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  margin-bottom: 35px;
  color: #001B35;
  font-weight: 400;
  position: relative;
  min-height: 300px; }
  .Tile--small {
    padding: 20px 30px;
    margin-bottom: 25px;
    min-height: auto; }
  .Tile::after {
    content: "";
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: 0px 5px 30px rgba(0, 27, 53, 0.1);
    opacity: 0; }
  .Tile:active[href], .Tile:focus[href], .Tile:hover[href], .Tile.is-hovered[href] {
    color: #001B35; }
    .Tile:active[href]::after, .Tile:focus[href]::after, .Tile:hover[href]::after, .Tile.is-hovered[href]::after {
      opacity: 1; }
  .Tile-body {
    flex-grow: 2; }
    .Tile-body img {
      max-height: 64px; }
    .Tile-body h5 {
      margin: 20px 0px 5px; }
  .Tile-footer {
    border-top: 1px solid rgba(0, 71, 160, 0.25);
    padding-top: 20px; }
    .Tile-footer .Author {
      font-size: 14px; }
    .Tile-footer .Function {
      font-size: 14px;
      color: rgba(0, 27, 53, 0.5); }

.Label {
  background-color: rgba(0, 71, 160, 0.15);
  color: #001B35;
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 25px 5px;
  border-radius: 18px;
  margin-right: 10px;
  font-weight: 400; }
  .Label--transparent {
    background-color: transparent; }
  .Label--border {
    border: 1px solid rgba(0, 27, 53, 0.1); }
  .Label--link {
    transition-property: border;
    transition-duration: 200ms;
    transition-timing-function: linear; }
    .Label--link:active, .Label--link:focus, .Label--link:hover, .Label--link.is-hovered, .Label--link.is-active {
      color: #001B35;
      transition-property: border;
      transition-duration: 200ms;
      transition-timing-function: linear;
      border: 1px solid #001B35;
      text-decoration: none; }

.Faq {
  margin-bottom: 100px; }
  .Faq-item {
    border: 1px solid rgba(0, 27, 53, 0.1);
    margin-bottom: 15px;
    padding: 30px 120px 30px 45px; }
    .Faq-item.is-active {
      padding-bottom: 5px; }
      .Faq-item.is-active .Faq-question {
        padding-bottom: 25px; }
        .Faq-item.is-active .Faq-question .Faq-icon--open {
          display: none; }
        .Faq-item.is-active .Faq-question .Faq-icon--close {
          display: block; }
    .Faq-item:last-of-type {
      margin-bottom: 0; }
    .Faq-item .Title {
      margin-bottom: 0; }
    .Faq-item .SubTitle {
      color: #001B35;
      font-weight: 600; }
  .Faq-question {
    cursor: pointer;
    font-size: 87.5%;
    font-weight: 500;
    letter-spacing: 1.5px;
    line-height: 2.14;
    user-select: none;
    color: #0047A0;
    padding-bottom: 0px;
    transition-property: padding-bottom;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: relative; }
    .Faq-question .Faq-icon {
      position: absolute;
      right: -75px; }
      .Faq-question .Faq-icon--open {
        display: block; }
      .Faq-question .Faq-icon--close {
        display: none; }
  .Faq-answer {
    transition-property: max-height;
    transition-duration: 200ms;
    transition-timing-function: linear;
    max-height: 0;
    overflow: hidden; }
  .FaqAnswer-body .Media-object {
    display: inline-block;
    margin-bottom: 35px; }
  .FaqAnswer-body .Text {
    margin-bottom: 15px; }

/* Print */
@media print {
  *,
  *::before,
  *::after,
  *::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    background: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    letter-spacing: 0;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]::after {
    content: " (" attr(href) ")"; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: ""; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #9e9e9e;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  /**
     * General
     */
  html {
    font-family: sans-serif;
    font-size: 12pt; }
  .Container {
    max-width: none; }
  h1,
  h2,
  h3 {
    margin-bottom: 12pt;
    text-transform: none; }
  h1 {
    font-size: 24pt; }
  h2 {
    font-size: 18pt; }
  h3 {
    font-size: 14pt; } }
