.Spinner {
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: #333;
    border-radius: 100%;  
    -webkit-animation: spinner 1.0s infinite ease-in-out;
    animation: spinner 1.0s infinite ease-in-out;
}

@-webkit-keyframes spinner {
    0% { 
        -webkit-transform: scale(0) 
    }
    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}
  
@keyframes spinner {
    0% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 
    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
}