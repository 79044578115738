/* Logo */
.Logo {
    flex-shrink: 0;
    margin-right: auto;

    svg {
        display: block;
        width: u(215);

        @include mq($until: $viewport--md - 1) {
            width: u(215);
        }
    }
}
