/* Main */
.Nav--main {

    @include mq($until: $viewport--lg - 1) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
    }

    // List
    .Nav-list {
        margin-left: u($spacing-unit * -1);

        @include mq($until: $viewport--lg - 1) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    // Item
    .Nav-item {
        margin-left: u($spacing-unit--xs * 6);

        &:not(:hover) {
            .Nav-dropdown {
                opacity: 0;
                visibility: hidden;
            }
        }

        @include mq($from: $viewport--lg) {
            @include position(relative, $z: 1);
            display: flex;
            justify-content: center;
            align-items: center;

            .Caret {
                @include caret(8, 1, bottom, currentColor, $header-bg-color);
                position: absolute;
                margin-left: u($spacing-unit--xs);
                top: 8px;
                right: -15px;
            }

            .Nav-dropdown {           
                @include transition(#{opacity, visibility});
                @include position(absolute, $top: 100%);
                left: calc(50% + 40px);
                transform: translateX(-50%);
                color: $black;
                background-color: $white;
                margin-top: u($spacing-unit--sm);
                -webkit-box-shadow: 0px 5px 30px 0px rgba($black, 0.1);
                -moz-box-shadow: 0px 5px 30px 0px rgba($black, 0.1);
                box-shadow: 0px 5px 30px 0px rgba($black, 0.1);
                width: u(720);
                padding: u($spacing-unit--md * 2, $spacing-unit--md * 3);

                &.Grid--withLargeGutter > .Grid-cell {
                    &:first-of-type {
                        padding-left: 0px;
                    }
                }

                &::before {
                    content: "";
                    width: 100%;
                    background-color: transparent;
                    height: 20px;
                    top: -20px;
                    left: 0;
                    position: absolute;

                    // @include
                }

                .u-linkClean {
                    &.is-active,
                    &:active,
                    &:focus,
                    &:hover {
                        color: $cc-pink;
                    }
                }
            }
        }

        @include mq($until: $viewport--xl) {
            margin-left: u($spacing-unit--xs * 4);
        }


        @include mq($until: $viewport--lg - 1) {
            margin-left: u(0);

            + .Nav-item {
                margin-top: u($spacing-unit--xs * 3);
            }

            .show-nav & {
                @include transition(#{opacity, transform}, #{300ms, 1s}, #{linear, cubic-bezier(.19,1,.22,1)});

                @for $i from 1 through 10 {
                    $delay: (0.02s * $i);

                    &:nth-child(#{$i}) {
                        transition-delay: $delay;
                    }
                }
            }

            html:not(.show-nav) & {
                opacity: 0;
                transform: translateY(#{u($spacing-unit)});
            }

            .Nav-dropdown {
                display: none;
            }
        }
    }

    // Link
    .Nav-link {
        font-weight: 500;
        color: $cc-blue;
        padding-left: u(10);
        position: relative;

        &::before {
            content: "";
            background-color: rgba($cc-blue, 0.2);
            @include dimensions(34);
            border-radius: 50%;
            position: absolute;
            left: u(0);
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            @include transition(opacity);
        }

        &.is-active,
        &:active,
        &:focus,
        &:hover {
            color: $cc-dark-blue;
            font-weight: 500;

            &::before {
                @include transition(opacity);
                opacity: 1;
            }
        }
    }
}
