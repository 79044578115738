/* Header */
.Header {
    background-color: $header-bg-color;
    padding-top: u($spacing-unit);
    padding-bottom: u($spacing-unit);

    > .Container {
        display: flex;
        align-items: center;
    }
}
html {
    &.show-nav {
        .Header {
            top: 0px;
            position: fixed;
            width: 100%;

            &--sticky {
                will-change: unset;
                transition: transform 200ms linear;
                position: fixed;
            
                &.is-pinned {
                    transform: none;
                }
            
                &.is-unpinned {
                    transform: none;
                }
            }
        }
    }
}


html:not(.show-nav) {
    
    .Header {
        &--sticky {
            will-change: transform;
            transition: transform 200ms linear;
            position: fixed;
            width: 100vw;
            box-shadow: none;
        
            &.is-pinned {
                transform: translateY(0%);
            }
        
            &.is-unpinned {
                transform: translateY(-100%);
            }
        }

        &.is-notTop {
            box-shadow: u(0) u(5) u(30) $cc-dark-blue-10;
        }
        
        &.is-top {

            + .Main {
                margin-top: u(180);
                @include transition(margin-top);
        
                @include mq($until: $viewport--lg - 1) {
                    margin-top: u(80);
                }
            }
        }
    }
}

