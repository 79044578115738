/* Fields: select */
select {
    cursor: pointer;
    width: 100% !important;

    // Fixed or fluid width
    @if is-false($form-element-fluid) and not is-null($form-element--default) {
        @include mq($from: $viewport--sm) {
            max-width: u($form-element--default);
        }
    }
}

.#{$form-namespace}Form-field--select {
    @extend %Form-field--reset;
    @extend %Form-field--shadow;
    background-image: url("../img/icons/arrow-select.svg");
    background-position: right u($spacing-unit--sm) center;
    background-repeat: no-repeat;
    background-size: u($spacing-unit--sm);
    box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow);
    line-height: normal;
    overflow: hidden;
    padding: em($form-element-padding--t, $form-select-element-icon-width, $form-element-padding--b, $form-element-padding--l);
}

.#{$form-namespace}Form-field--select[multiple] {
    @extend %Form-field--reset;
    height: auto;

    option {
        padding: em(halve($form-element-padding--t), $form-element-padding--r, halve($form-element-padding--b), $form-element-padding--l);

        + option {
            border-top: 1px solid $form-element-border-color;
        }
    }
}