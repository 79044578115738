/* Social Media */
.Nav--socialMedia {
    padding-top: u($spacing-unit--xs * 3);

    // List
    .Nav-list {

    }

    // Item
    .Nav-item {
        margin-right: u($spacing-unit--md);
        @include dimensions(16);
    }

    // Link
    .Nav-link {
        @include dimensions(16);

        svg {
            vertical-align: top;
        }

        svg {
            path {
                @include transition(fill);
                fill: $white;
            }
        }

        &:active,
        &:focus,
        &:hover {
            svg {
                path {
                    @include transition(fill);
                    fill: $cc-pink;
                }
            }
        }
    }
}
