.Faq {
    margin-bottom: u($spacing-unit--md * 5);

    &-item {
        border: px(1) solid $cc-dark-blue-10;
        margin-bottom: u(15);
        padding: u($spacing-unit--xs * 6, $spacing-unit--md * 6, $spacing-unit--xs * 6, $spacing-unit--xs * 9);

        &.is-active {
            padding-bottom: u($spacing-unit--xs);

            .Faq-question {
                padding-bottom: u(25);

                .Faq-icon {
                    &--open {
                        display: none;
                    }

                    &--close {
                        display: block;
                    }
                }
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        .Title {
            margin-bottom: 0;
        }

        .SubTitle {
            color: $cc-dark-blue;
            font-weight: 600;
        }
    }

    &-question {
        cursor: pointer;
        font-size: 87.5%;
        font-weight: 500;
        letter-spacing: px(1.5);
        line-height: 2.14;
        user-select: none;
        color: $cc-blue;
        padding-bottom: u(0);
        @include transition(padding-bottom);
        position: relative;

        .Faq-icon {
            position: absolute;
            right: u(-75);

            &--open {
                display: block;
            }

            &--close {
                display: none;
            }
        }
    }

    &-answer {
        @include transition(max-height);
        max-height: 0;
        overflow: hidden;

        // :last-child {
        //     margin-bottom: 0;
        // }
    }

    &Answer-body {
        .Media {
            &-object {
                display: inline-block;
                margin-bottom: u($spacing-unit--xs * 7);
            }
        }

        .Text {
            margin-bottom: u($spacing-unit--xs * 3);
        }
    }
}
