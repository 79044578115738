/* Blocks */
.Block {
    padding: u(120, 0);
    background-color: $cc-grey;

    @include mq($until: $viewport--md - 1) {
        padding: u(60, 0);
    };

    &--white {
        background-color: $white;
    }

    &--small {
        padding-bottom: u(80);
    }

    &--news {
        position: relative;
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            right: u(-40);
            bottom: u(-40);
            background-image: url("../img/element.svg");
            @include dimensions(263, 236);
            background-position: center;
            background-position: contain;
        }
    }

    &--partners {
        padding: u(0, 0);

        @include mq($until: $viewport--md - 1) {
            padding: u(0, 0);
        };

        &.Block--white {

        }

        &.Block--white {
            .Slider {
                background-color: $white;
            }
        }

        .has-background {
            background-image: url("../img/background.svg");
            background-position: center top;
            background-size: cover;
            color: $white;
            padding-top: u(100);
            padding-bottom: u(105);

            hr {
                border-color: $white;
            }
        }

        .Slider {
            background-color: $cc-grey;
            height: u(200);
            margin-top: u(-100);

            &-item {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

    }

    + .Block {}

    &.u-imageFirst {
        .Grid {
            flex-direction: row-reverse;;
        }
    }

}
