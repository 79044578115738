/* Slider: navigation */
.Slider-button {
    @include dimensions(37, 37);
    @include position(absolute, $top: 50%, $z: 10);
    margin-top: u(-22px);
    cursor: pointer;

    &.is-disabled {
        cursor: auto;
        opacity: 0.35;
        pointer-events: none;
    }

    &.swiper-button-lock {
        display: none;
    }

    &.is-locked {
        display: none;
    }

    &:hover,
    &:active {
        svg {
            path {
                fill: $white;
                @include transition(fill);
            }

            circle {
                fill: $cc-dark-blue;
                stroke: transparent;
                @include transition(#{stroke, fill});
            }

            g {
                opacity: 1;
                @include transition(opacity);
            } 
        }
    }

    &:focus {
        outline: none;
    }
}

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
    right: u(45);
    left: auto;

    @include mq($until: $viewport--md) {
        right: auto;
        left: u(0);
    }
}

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
    right: 0px;
    transform: rotate(180deg);
    left: auto;

    @include mq($until: $viewport--md) {
        right: auto;
        left: u(45);
    }
}
